import React, { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'
import { useOutlets } from 'application/domain/useCase/outlets/getOutlets'
import { Outlet } from 'application/domain/entity/outlet/Outlet'
import { setOutlet } from 'application/domain/store/userReducer'

import Loader from '../../Loader'
import BottomSheet from '../BottomSheet'
import OutletItem from '../../OutletItem'

import s from './OutletsListMW.module.scss'

const OutletsListMW = () => {
  const { outlet } = useSelector(({ userReducer }: RootState) => ({
    outlet: userReducer.outlet,
  }))
  const { data, isLoading } = useOutlets(outlet ? outlet.id : 0)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf('outlets') !== -1
  }, [location])

  const selectBar = useCallback(
    (item: Outlet) => {
      sessionStorage.setItem('outlet_id', item.id.toString())
      setOutlet(item)(dispatch)
      //   setStage(2)
      navigation(-1)
    },
    [navigation, dispatch],
  )

  return (
    <div>
      <BottomSheet
        opened={opened}
        // autoHeight
        onSheetClosed={() => {
          if (location.hash.indexOf('outlets') !== -1) {
            navigation(-1)
          }
        }}
      >
        <div className={s.container}>
          <div className={s.title}>Список кофе-баров</div>
          {isLoading ? (
            <Loader size={32} />
          ) : (
            <div className={s.outlets}>
              {data.map((item: Outlet) => (
                <OutletItem data={item} onClick={selectBar} key={`outlet_item_${item.id}`} />
              ))}
            </div>
          )}
        </div>
      </BottomSheet>
    </div>
  )
}

export default React.memo(OutletsListMW)
