import { RootState } from 'application/domain/store/reduxStore'
import { useActuallyOrders, useOrdersHistory } from 'application/domain/useCase/orders/getOrders'
import { useOutlets } from 'application/domain/useCase/outlets/getOutlets'
import Header from 'application/presentation/common/Header/Header'
import Loader from 'application/presentation/common/Loader'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { ActuallyOrder, ClientOrder } from 'application/domain/entity/order/Order'
import { Outlet } from 'application/domain/entity/outlet/Outlet'

import s from './OrdersListPage.module.scss'
import OrderItem from './components/OrderItem'

const OrdersListPage = () => {
  const token = useSelector(({ userReducer }: RootState) => userReducer.token)
  const { data: outlets, isLoading: outletsIsLoading } = useOutlets()
  const { data: actuallyOrders, isLoading: actuallyOrdersIsLoading } = useActuallyOrders(
    token || '',
  )
  const { data: orders, isLoading: ordersIsLoading } = useOrdersHistory(token || '')

  const { actually, history } = useMemo(() => {
    let actOrders: Array<ActuallyOrder & { outlet: Outlet }> = []
    let histOrders: Array<ClientOrder & { outlet: Outlet }> = []
    if (actuallyOrders) {
      if (actuallyOrders.open_orders.length) {
        actOrders = _.map(actuallyOrders.open_orders, (item: any) => ({
          ...item,
          outlet: _.find(outlets, { id: item.outlet_id }),
        }))
      }
      if (actuallyOrders.open_orders_list.length) {
      }
    }
    if (orders) {
      histOrders = _.map(orders, (item: any) => ({
        ...item,
        outlet: _.find(outlets, { id: item.outlet_id }),
      }))
    }
    return { actually: actOrders, history: histOrders }
  }, [actuallyOrders, outlets, orders])

  return (
    <div className={s.container}>
      <Header title={'Заказы'} />
      <div className={s.content}>
        {outletsIsLoading || actuallyOrdersIsLoading ? (
          <Loader size={32} />
        ) : (
          <>
            {actually.length ? (
              <div className={s.actually_block}>
                <div className={cn(s.title, s.orange)}>Актуальный заказ</div>
                {actually.map((item: ActuallyOrder & { outlet: Outlet }) => (
                  <OrderItem data={item} key={`order_item_${item.id}`} isActive />
                ))}
              </div>
            ) : (
              <></>
            )}
            {history.length ? (
              <div className={s.history_block}>
                <div className={s.title}>Прошлые заказы</div>
                {history.map((item: ClientOrder & { outlet: Outlet }) => (
                  <OrderItem data={item} key={`order_item_${item.order_id}`} />
                ))}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(OrdersListPage)
