import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import cn from 'classnames'
import { Portal } from 'react-portal'
import { ReactComponent as CloseSvg } from 'images/icons/close_icon.svg'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as PencilSvg } from 'images/icons/pencil.svg'
import { useBalance, useUser } from 'application/domain/useCase/user/getUser'
import { AuthContext } from 'application/presentation/context/Authorization'
import { alohaIds } from 'application/domain/useCase/outlets/getOutlets'

import { Button, CircleProgressBar } from '../uiComponents'
import Loader from '../Loader'

import s from './Menu.module.scss'

type Props = {
  opened: boolean
  setOpened: Function
}

const Menu = ({ opened, setOpened }: Props) => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const { token, outlet } = useSelector(({ userReducer }: RootState) => ({
    token: userReducer.token,
    outlet: userReducer.outlet,
  }))
  const { data: userData, isLoading } = useUser(token)

  const { data: balanceData } = useBalance(userData)

  const balance: {
    cashback_sum: number
    cards_sum: number
  } = useMemo(() => {
    return balanceData ? balanceData : null
  }, [balanceData])

  const freeCup = useMemo(() => {
    return userData && (userData.free_cup || userData.free_cup === 0) ? userData.free_cup : 5
  }, [userData])

  const closeMenu = useCallback(() => setOpened(false), [setOpened])
  useEffect(() => {
    if (opened) {
      document.body.classList.add('menu_opened')
    } else {
      document.body.classList.remove('menu_opened')
    }
    return () => document.body.classList.remove('menu_opened')
  }, [opened])

  const goTo = useCallback(
    (to: string) => {
      setOpened(false)
      navigate(to)
    },
    [navigate, setOpened],
  )
  const logout = useCallback(() => {
    authContext.logout()
    closeMenu()
  }, [authContext, closeMenu])

  return (
    <Portal>
      <div className={cn({ [s.container]: true, [s.opened]: opened })}>
        <div className={s.close} onClick={closeMenu}>
          <CloseSvg />
        </div>
        <div className={s.inner_container}>
          {isLoading && token ? (
            <Loader size={32} />
          ) : (
            <>
              {userData ? (
                <>
                  <div className={s.user_block} onClick={() => goTo('/edit_profile')}>
                    <div className={s.user_pic}>{userData.name[0]}</div>
                    <div className={s.user_info}>
                      <div className={s.user_name}>{userData.name}</div>
                      <div className={s.user_phone}>{userData.phone}</div>
                    </div>
                    <div className={s.edit_icon}>
                      <PencilSvg />
                    </div>
                  </div>
                  <div className={s.free_coffe} onClick={() => goTo('/free_coffe')}>
                    <div className={s.info}>
                      <div className={s.free_title}>6 стакан в подарок</div>
                      <div className={s.free_subtitle}>
                        {freeCup === 0
                          ? 'Следующий кофе бесплатный'
                          : `Еще ${freeCup} кофе до бесплатного`}
                      </div>
                    </div>
                    <CircleProgressBar
                      color="#fff"
                      size="small"
                      procent={((5 - freeCup) / 5) * 100}
                      circleWidth={26}
                      backgroundColor={'#FF9E44'}
                    >
                      <div className={s.free_counter}>{5 - freeCup}</div>
                    </CircleProgressBar>
                  </div>
                </>
              ) : (
                <Button
                  text={'Войти в аккаунт'}
                  color="orange"
                  onClick={() => goTo('/authorization')}
                />
              )}

              <div className={s.menu_list}>
                {userData ? (
                  <>
                    <div className={cn(s.item, s.bold, s.item_balance)}>
                      Мой баланс{' '}
                      <span>
                        {alohaIds.indexOf(outlet?.id || 0) !== -1
                          ? balance?.cashback_sum
                          : balance?.cards_sum}{' '}
                        ₽
                      </span>
                    </div>
                    <div className={cn(s.item, s.bold)} onClick={() => goTo('/orders')}>
                      Мои заказы
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className={cn(s.item, s.bold)} onClick={() => goTo('/outlets')}>
                  Список кофе-баров
                </div>
                <div className={s.item} onClick={() => goTo('/contacts')}>
                  Контакты
                </div>
                <div className={s.item} onClick={() => goTo('/franchise')}>
                  Франшиза
                </div>
                <a className={s.item} href="https://ddwork.ru/">
                  Работа в DonDonuts
                </a>
                {userData ? (
                  <div className={s.item} onClick={logout}>
                    Выйти
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Portal>
  )
}

export default React.memo(Menu)
