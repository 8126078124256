import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { RootState } from 'application/domain/store/reduxStore'
import { setUser } from 'application/domain/store/userReducer'
import Header from 'application/presentation/common/Header/Header'
import { ConfirmationMW } from 'application/presentation/common/ModalWindows'
import { Button } from 'application/presentation/common/uiComponents'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import ToastNotify from 'application/presentation/context/ToastNotify'
import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'

import s from './EditProfilePage.module.scss'

const EditProfilePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const [name, setName] = useState<string>(user ? user.name : '')
  const [phone, setPhone] = useState<string>(user ? user.phone : '')
  const [editIsLoading, setEditIsLoading] = useState<boolean>(false)
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      setName(user.name)
      setPhone(user.phone)
    }
  }, [user])

  const saveChanges = useCallback(() => {
    setEditIsLoading(true)
    apiClient
      .post('/api/personal', {
        name: name,
      })
      .then(() => {
        queryClient.invalidateQueries(['user'])
        setEditIsLoading(false)
        ToastNotify('Профиль отредактирован!')
        if (user) {
          setUser({ ...user, name })(dispatch)
          navigate(-1)
        }
        // navigation.goBack()
      })
      .catch(() => {
        setEditIsLoading(false)
      })
  }, [name, user, dispatch, navigate])

  const sendRemoveRequest = useCallback(() => {
    setDeleteIsLoading(true)
    apiClient
      .post('/api/client/sendMailRequest', {
        name,
        phone,
        email: '',
        type: 'delete_account',
      })
      .then(() => {
        setDeleteIsLoading(false)
        ToastNotify('Заявка на удаление отправлена!')
      })
      .catch(() => {
        setDeleteIsLoading(false)
        ToastNotify('Произошла ошибка при отправлении заявки!')
      })
  }, [name, phone])

  return (
    <div className={s.container}>
      <Header title="Редактирование" />
      <div className={s.content}>
        <UniversalInputComponent type="text" title="Имя" value={name} onChange={setName} />
        <UniversalInputComponent type="phone" title="Телефон" value={phone} onChange={() => {}} />
        <div className={s.delete_info}>
          Вы можете удалить свой аккаунт со всеми своими данными. Аккаунт не подлежит
          восстановлению.
        </div>
        <NavLink className={s.delete_btn} to="/edit_profile#confirmation">
          Удалить аккаунт
        </NavLink>
        <Button
          text="Сохранить"
          containerClassName={s.btn_container}
          color="orange"
          onClick={saveChanges}
          isLoading={editIsLoading}
        />
      </div>

      <ConfirmationMW
        title={'Вы уверены, что хотите удалить свой аккаунт?'}
        onConfirm={sendRemoveRequest}
        confirmIsLoading={deleteIsLoading}
      />
    </div>
  )
}

export default React.memo(EditProfilePage)
