import { configureStore } from '@reduxjs/toolkit'

import userReducer from './userReducer'
import basketReducer from './basketReducer'
import payReducer from './payReducer'

const store = configureStore({
  reducer: {
    userReducer,
    basketReducer,
    payReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
