import { Outlet } from 'application/domain/entity/outlet/Outlet'

import { alohaIds } from '../outlets/getOutlets'

import { AuthUser } from './../../entity/user/User'
export const getSumWithBonus = (
  fullPrice: number,
  outlet: Outlet,
  balance: {
    cashback_sum: number
    cards_sum: number
  },
  isBalance: boolean,
  user: AuthUser,
  mergedData: any,
) => {
  let bonus = 0
  let price = fullPrice

  if (alohaIds.indexOf(outlet?.id) !== -1) {
    if (isBalance && user && balance?.cashback_sum) {
      let coffePrice = 0
      mergedData.map((item: any) => {
        if (item.isDrink) {
          coffePrice += item.full_cost
        }
      })
      const coffeHalfPrice = coffePrice / 2
      bonus = coffeHalfPrice - balance.cashback_sum >= 0 ? balance.cashback_sum : coffeHalfPrice
      price = fullPrice - bonus
      return {
        cost: price,
        bonus: bonus,
      }
    }
    return {
      cost: price,
      bonus: 0,
    }
  }

  if (isBalance && user && balance?.cards_sum) {
    let coffePrice = 0
    mergedData.map((item: any) => {
      if (item.isDrink) {
        coffePrice += item.full_cost
      }
    })
    bonus = coffePrice - balance.cards_sum >= 0 ? balance.cards_sum : coffePrice
    price = fullPrice - bonus
  }
  return {
    cost: price,
    bonus: bonus,
  }
}
