/* eslint-disable @typescript-eslint/naming-convention */
import apiClient from 'application/data/apiClient'
import ToastNotify from 'application/presentation/context/ToastNotify'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as PlusSvg } from 'images/icons/orange_add_circle_icon.svg'
import { getProductPrice, Product } from 'application/domain/entity/product/Product'
import { addProductWithMinPrice } from 'application/domain/useCase/basket/addToBasket'
import { useProducts } from 'application/domain/useCase/products/getProducts'
import { RootState } from 'application/domain/store/reduxStore'
import ProductImage from 'application/presentation/common/uiComponents/ProductImage'

import s from './AddProductsBlock.module.scss'

const AddProductsBlock = () => {
  const dispatch = useDispatch()
  const outlet = useSelector(({ userReducer }: RootState) => userReducer.outlet)
  const { data: productsData, isLoading: productsIsLoading } = useProducts(outlet ? outlet.id : 0)
  const [products, setProducts] = useState<Array<Product> | null>(
    productsData
      ? () => {
          let prod = _.sampleSize(
            _.filter(
              productsData,
              (p) =>
                (p.client_category_name === 'Пончики' || p.client_category_name === 'Еда') &&
                p.availability_status === 'in_stock' &&
                getProductPrice(p) > 0,
            ),
            3,
          )
          if (prod && prod.length) {
            return prod
          }
          return _.sampleSize(
            _.filter(
              productsData,
              (p) => p.availability_status === 'in_stock' && getProductPrice(p) > 0,
            ),
          )
        }
      : null,
  )

  const addProduct = useCallback(
    (prod: Product) =>
      addProductWithMinPrice(prod, {
        callback: () => {
          setProducts(_.filter(products, (p) => p.id !== prod.id))
          ToastNotify(`${prod.product_name} добавлен в корзину!`)
        },
      })(dispatch),
    [dispatch, products],
  )

  return products && products.length ? (
    <div className={s.container}>
      <div className={s.title}>Добавить в заказ?</div>
      <div className={s.products_container}>
        {products.map((item) => {
          return (
            <div className={s.item} key={`add_product_${item.id}`} onClick={() => addProduct(item)}>
              <ProductImage
                containerClassName={s.img_container}
                imageUrl={item.image}
                isDrink={item.isDrink}
              />
              <div className={s.item_info}>
                <div className={s.item_name}>{item.product_name}</div>
                <div className={s.add_btn}>
                  <PlusSvg />
                  {/* <SvgXml xml={orangeAddSvg} width={15} height={15} /> */}
                  <div className={s.item_price}>{getProductPrice(item)} ₽</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(AddProductsBlock)
