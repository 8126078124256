import apiClient from 'application/data/apiClient'
import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { ReactComponent as LockSvg } from 'images/icons/lock_icon.svg'
import { ReactComponent as RemoveSvg } from 'images/icons/remove_icon.svg'
import { ReactComponent as AddSvg } from 'images/icons/add_icon.svg'
import { SelectAdditionalMW } from 'application/presentation/common/ModalWindows'
import { useNavigate } from 'react-router'
import ProductImage from 'application/presentation/common/uiComponents/ProductImage'

import s from './AdditionalsSelector.module.scss'

type Props = {
  selected: any
  notIncluded: any
  selectAdditional: Function
  removeAdditional: Function
  selectedVolume: number
}

const AdditionalItem = React.memo(
  ({ item, style, selected, openSelectorMW, removeAdditional }: any) => {
    const selectedItem = useMemo(() => {
      return selected ? _.find(item.items, { selected: true }) : null
    }, [selected, item])

    return (
      <div
        className={s.item}
        onClick={() => (!selected ? openSelectorMW(item) : removeAdditional(item))}
      >
        <div className={s.item_inner}>
          <div className={s.img_container}>
            <ProductImage
              containerClassName={s.image}
              imageUrl={selectedItem ? selectedItem.image : item.image}
            />
          </div>
          {selectedItem ? <div className={s.item_txt}>{selectedItem.name}</div> : <></>}
          {!selected && <div className={s.item_txt}>{item.name}</div>}
        </div>
        <div className={s.icon}>
          {item.fixed ? <LockSvg /> : selected ? <RemoveSvg /> : <AddSvg />}
        </div>
      </div>
    )
  },
)

const AdditionalsSelector = ({
  selected,
  notIncluded,
  selectAdditional,
  removeAdditional,
  selectedVolume,
}: Props) => {
  const navigation = useNavigate()
  const [additionalsMwData, setAdditionalsMwData] = useState<{
    title: string
    data: Array<any>
    selectAdditional: Function
  }>({
    title: '',
    data: [],
    selectAdditional: () => {},
  })
  const openSelectorMW = useCallback(
    (item: any) => {
      setAdditionalsMwData({
        title: item.name,
        data: item.items,
        selectAdditional: (addId: number) => selectAdditional(item, addId),
      })
      navigation('#additionals')
    },
    [navigation, selectAdditional],
  )
  return (
    <div className={s.additionals_container}>
      {selected && selected.length ? (
        <div className={s.content}>
          <div className={s.title}>В составе:</div>
          <div className={s.items_container}>
            {selected.map((item: any, i: number) => (
              <AdditionalItem
                item={item}
                key={`selected_${item.id}_${i}`}
                selected
                openSelectorMW={openSelectorMW}
                removeAdditional={removeAdditional}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {notIncluded && notIncluded.length ? (
        <div className={s.content}>
          <div className={s.title}>Добавим?</div>
          <div className={s.items_container}>
            {notIncluded.map((item: any, i: number) => (
              <AdditionalItem
                item={item}
                key={`notSelected_${item.id}`}
                // style={{
                //   width: itemWidth,
                //   height: itemWidth,
                //   marginRight: (i + 1) % 3 === 0 ? 0 : itemMargin,
                // }}
                openSelectorMW={openSelectorMW}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      <SelectAdditionalMW data={additionalsMwData} selectedVolume={selectedVolume} />
    </div>
  )
}

export default React.memo(AdditionalsSelector)
