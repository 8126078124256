import React, { useEffect, useState } from 'react'
import { ReactComponent as LocationSvg } from 'images/icons/location_icon.svg'
import { ReactComponent as TriggerSvg } from 'images/icons/right_gray_trigger.svg'
import { NavLink } from 'react-router-dom'
import { getGeolocationDistance } from 'application/utils/getLocationDistance'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'

import { OutletsListMW } from '../../ModalWindows'
import ErrorMsg from '../ErrorMsg/ErrorMsg'

import s from './SelectOutletBlock.module.scss'

type Props = {
  technicalBreak: string | null
}

const SelectOutletBlock = ({ technicalBreak }: Props) => {
  const { outlet } = useSelector(({ userReducer }: RootState) => ({
    outlet: userReducer.outlet,
  }))
  const [distance, setDistance] = useState<string>('')
  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (outlet) {
          setDistance(
            getGeolocationDistance(
              outlet?.height,
              outlet?.width,
              position.coords.longitude,
              position.coords.latitude,
            ).toFixed(1),
          )
        }
      })
    }
  }, [outlet])

  return (
    <>
      <NavLink className={s.container} to={'#outlets'}>
        <div className={s.name}>{outlet ? outlet.name : 'Не выбрана точка'}</div>
        <div className={s.right}>
          {distance ? (
            <>
              <span className={s.icon}>
                <LocationSvg />
              </span>
              <span className={s.distance}>
                {distance === '0.0' ? 'Вы здесь' : `${distance}км`}
              </span>
            </>
          ) : (
            <></>
          )}
          <span className={s.trigger}>
            <TriggerSvg />
          </span>
        </div>
      </NavLink>
      {technicalBreak ? (
        <ErrorMsg
          text={`Технический перерыв ${technicalBreak}. \n Выберите другую кофейню или попробуйте сделать заказ позже`}
          containerStyle={s.error_container}
        />
      ) : null}
      <OutletsListMW />
    </>
  )
}

export default React.memo(SelectOutletBlock)
