/* eslint-disable @typescript-eslint/naming-convention */
import Loader, { AppLoader } from 'application/presentation/common/Loader'
import React, { useEffect, useState } from 'react'
import { Portal } from 'react-portal'
import { Route, Routes } from 'react-router-dom'
import MenuNavigation from 'application/presentation/navigation/MenuNavigation'
import { useDispatch } from 'react-redux'
import { ToastContainer, Zoom } from 'react-toastify'
import { Page, PageContent } from 'framework7-react'
import apiClient from 'application/data/apiClient'
import { setBasket } from 'application/domain/store/basketReducer'
import { setOutlet, setTableId, setUser, setUserToken } from 'application/domain/store/userReducer'
import { getOutlets } from 'application/domain/useCase/outlets/getOutlets'
import _ from 'lodash'
import { getUserPaymentCards } from 'application/domain/useCase/payments/userPaymentsCard'
import { setPayMethod } from 'application/domain/store/payReducer'

import s from './AppView.module.scss'

const AppView = () => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        //@ts-ignore
        if (window.ENV && window.ENV.url) {
          //@ts-ignore
          apiClient.defaults.baseURL = window.ENV.url
        }
        const urlParams = new URLSearchParams(window.location.search)
        const outletId = urlParams.get('outlet_id')
        const tableId = urlParams.get('table_id')
        const table = urlParams.get('table')
        const localOutletId = sessionStorage.getItem('outlet_id')
        const localTableId = sessionStorage.getItem('table_id')
        const basket = localStorage.getItem('basketData')
        const token = localStorage.getItem('user_token')
        let outlet_id = outletId || localOutletId
        let table_id = tableId || table || localTableId
        // TODO: че я за хуйню написал ниже?
        if (outletId || tableId || table) {
          if (outletId && outletId !== localOutletId) {
            sessionStorage.setItem('outlet_id', outletId)
          }
          if (tableId && tableId !== localTableId) {
            sessionStorage.setItem('table_id', tableId)
          }
          if (table && table !== localTableId) {
            sessionStorage.setItem('table_id', table)
          }
        }
        const outlets = await getOutlets(outlet_id ? parseInt(outlet_id) : 0)
        if (outlets && outlet_id) {
          const o = _.find(outlets.outlets ? outlets.outlets : outlets, {
            id: parseInt(outlet_id, 10),
          })
          setOutlet(o)(dispatch)
        }
        if (table_id) {
          setTableId(table_id)(dispatch)
        }
        if (basket) {
          try {
            const basketDat = JSON.parse(basket)
            setBasket(basketDat)(dispatch)
          } catch (e) {
            setBasket([])(dispatch)
          }
        }
        if (token) {
          const { data } = await apiClient.get('/api/user', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          setUser(data && data.data ? data.data : null)(dispatch)
          setUserToken(token)(dispatch)
          // @ts-ignore
          apiClient.defaults.headers.Authorization = `Bearer ${token}`
          const last_pay_method = localStorage.getItem('last_pay_method')
          if (last_pay_method && parseInt(last_pay_method, 10) > 5) {
            const cards = await getUserPaymentCards()
            const card = _.find(cards, { id: parseInt(last_pay_method, 10) })
            if (card) {
              setPayMethod(card.id)(dispatch)
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    bootstrapAsync().finally(() => {
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page pageContent={false}>
      <PageContent id="page_content">
        {isLoading ? (
          <AppLoader />
        ) : (
          <>
            <div className={s.app_container}>
              <Routes>
                <Route path="/*" element={<MenuNavigation />} />
              </Routes>
            </div>
            <Portal>
              <ToastContainer transition={Zoom} limit={1} autoClose={100} />
            </Portal>
          </>
        )}
      </PageContent>
    </Page>
  )
}

export default React.memo(AppView)
