import { fetchPaymentStatusBySberOrderId } from 'application/data/api/payments'
import { useQuery } from '@tanstack/react-query'

export const useSberPaymentStatus = (sberOrderId: string) => {
  const query = useQuery(
    ['checkpayment', sberOrderId],
    () => fetchPaymentStatusBySberOrderId(sberOrderId),
    {
      enabled: sberOrderId ? true : false,
      refetchInterval: 10000,
    },
  )
  return query
}
