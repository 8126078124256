import { useJsApiLoader, GoogleMap, MarkerF, MarkerClustererF } from '@react-google-maps/api'
import { Outlet } from 'application/domain/entity/outlet/Outlet'
import { RootState } from 'application/domain/store/reduxStore'
import { useOutlets } from 'application/domain/useCase/outlets/getOutlets'
import React, { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import locationPng from 'images/donuts_location.png'
import supportedLocationPng from 'images/donuts_location_supported.png'
import currentLocationPng from 'images/selected_location.png'
import vanLocationPng from 'images/van_location.png'
import supportedVanLocationPng from 'images/van_location_supported.png'
import { ReactComponent as BackSvg } from 'images/icons/go_back_icon.svg'
import { useNavigate } from 'react-router'

import s from './MapPage.module.scss'

const INITIAL_REGION = {
  lat: 56.098798,
  lng: 47.285116,
  zoom: 10,
  // latitudeDelta: 0.0922,
  // longitudeDelta: 0.0421,
}

const OutletsListPage = () => {
  const navigate = useNavigate()
  const mapRef = useRef<any>()
  const { selectedBar } = useSelector(({ userReducer }: RootState) => ({
    selectedBar: userReducer.outlet,
  }))
  const [openedBar, setOpenedBar] = useState<number>(0)
  const { data: outlets } = useOutlets(selectedBar ? selectedBar.id : 0)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCnZLUtXpncfeigG0PRxueF2e6W8pwycq8',
  })

  const [gMap, setMap] = useState(null)

  const onLoad = useCallback((map: any) => {
    // // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(INITIAL_REGION)
    // map.fitBounds(bounds)

    // setMap(map)
    map.setCenter(INITIAL_REGION)
  }, [])

  // const onUnmount = useCallback((map: any) => {
  //   setMap(null)
  // }, [])

  const onMarkerPress = useCallback(() => {
    // e.stopPropagation()
    mapRef.current.panTo({
      lat: 56.098798,
      lng: 47.285116,
    })
    // if (mapRef && mapRef.current) {
    //   setOpenedBar(coords.id)
    //   if (mwRef && mwRef.current) {
    //     // @ts-ignore
    //     setTimeout(() => mwRef.current.snapToIndex(1), 50)
    //   }
    // }
  }, [mapRef])

  return (
    <div className={s.container}>
      <div className={s.back_btn} onClick={() => navigate(-1)}>
        <BackSvg />
      </div>
      {isLoaded && outlets ? (
        <GoogleMap
          mapContainerClassName={s.map_container}
          center={INITIAL_REGION}
          zoom={12}
          ref={mapRef}
          // onLoad={onLoad}
          // onUnmount={onUnmount}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          <MarkerClustererF clusterClass={s.cluster}>
            {(clusterer) =>
              outlets.map((item: Outlet, index: number) => {
                const image =
                  openedBar === item.id
                    ? {
                        url: currentLocationPng,
                        scaledSize: new google.maps.Size(40, 40),
                      }
                    : item.type === 'outlet'
                    ? item.is_distance_order
                      ? {
                          url: supportedLocationPng,
                          scaledSize: new google.maps.Size(60, 60),
                        }
                      : {
                          url: locationPng,
                          scaledSize: new google.maps.Size(40, 60),
                        }
                    : item.is_distance_order
                    ? {
                        url: supportedVanLocationPng,
                        scaledSize: new google.maps.Size(60, 60),
                      }
                    : {
                        url: vanLocationPng,
                        scaledSize: new google.maps.Size(60, 60),
                      }

                return (
                  <MarkerF
                    key={`marker_${item.width}_${item.height}_${index}`}
                    position={{
                      lat: item.width,
                      lng: item.height,
                    }}
                    icon={image}
                    clusterer={clusterer}
                    // onClick={() => onMarkerPress()}
                  />
                )
              })
            }
          </MarkerClustererF>
          {/* Child components, such as markers, info windows, etc. */}
          <></>
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(OutletsListPage)
