import Loader from 'application/presentation/common/Loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as SberPaySvg } from 'images/payIcons/sberpay.svg'
import cn from 'classnames'
import { RootState } from 'application/domain/store/reduxStore'

import s from './PayButtons.module.scss'

type Props = {
  disabled?: boolean
  isLoading?: boolean
  onClick: (type: string) => void
  containerStyle?: any
}

const PayButtons = ({ disabled, isLoading, onClick, containerStyle }: Props) => {
  const { selectedPayMethod } = useSelector(({ payReducer }: RootState) => ({
    selectedPayMethod: payReducer.selectedPayMethod,
  }))
  switch (selectedPayMethod) {
    case 1:
      return (
        <div className={s.btn_block}>
          <div
            className={cn(s.btn, s.black, disabled ? s.disabled : '')}
            onClick={() => onClick('sberpay')}
          >
            {isLoading ? (
              <Loader containerClassName={s.loader} size={16} color="#fff" />
            ) : (
              <div className={s.content}>
                <SberPaySvg />
              </div>
            )}
          </div>
        </div>
      )
    case 4:
      return (
        <div className={s.btn_block}>
          <div
            className={cn(s.btn, s.orange, disabled ? s.disabled : '')}
            onClick={() => onClick('balance')}
          >
            {isLoading ? (
              <Loader containerClassName={s.loader} size={16} color="#fff" />
            ) : (
              <div className={s.content}>Оформить заказ</div>
            )}
          </div>
        </div>
      )

    case 5:
      return (
        <div className={s.btn_block}>
          <div
            className={cn(s.btn, s.orange, disabled ? s.disabled : '')}
            onClick={() => onClick('bank')}
          >
            {isLoading ? (
              <Loader containerClassName={s.loader} size={16} color="#fff" />
            ) : (
              <div className={s.content}>Оплата картой</div>
            )}
          </div>
        </div>
      )
    default:
      return (
        <div className={s.btn_block}>
          <div
            className={cn(s.btn, s.orange, disabled ? s.disabled : '')}
            onClick={() => onClick('bank')}
          >
            {isLoading ? (
              <Loader containerClassName={s.loader} size={16} color="#fff" />
            ) : (
              <div className={s.content}>Оплата картой</div>
            )}
          </div>
        </div>
      )
  }
}

export default React.memo(PayButtons)
