function toRad(Value: number): number {
  /** Converts numeric degrees to radians */
  return (Value * Math.PI) / 180
}
export const getGeolocationDistance = (lon1: number, lat1: number, lon2: number, lat2: number) => {
  var R = 6371 // Radius of the earth in km
  var dLat = toRad(lat2 - lat1) // Javascript functions in radians
  var dLon = toRad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}
