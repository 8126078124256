import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { ReactComponent as TriggerSvg } from 'images/icons/right_gray_trigger.svg'
import coffePng from 'images/coffe.png'
import apiClient from 'application/data/apiClient'
import { NavLink } from 'react-router-dom'

import s from './OrderItem.module.scss'

type Props = {
  data: any
  isActive?: boolean
}

const OrderItem = ({ data, isActive }: Props) => {
  const [image, setImage] = useState(
    data.products && data.products.length
      ? `${apiClient.defaults.baseURL}${data.products[0].image}`
      : coffePng,
  )
  const productsLength = useMemo(() => {
    return data.products && data.products.length ? data.products.length - 1 : 0
  }, [data])

  return (
    <NavLink
      className={s.container}
      to={`/orders/${isActive ? `${data.id}?is_open_order=true` : data.order_id}`}
    >
      <div className={s.img_block}>
        <img src={image} alt="" onError={() => setImage(coffePng)} />
        {productsLength ? <div className={s.products_length}>+{productsLength}</div> : <></>}
      </div>
      <div className={s.order_info}>
        <div className={s.title}>
          {moment(data.created_at ? data.created_at : data.create_time).format(
            'D MMMM YYYYг. HH:mm',
          )}
        </div>
        <div className={s.subtitle}>
          {data.outlet && data.outlet.address ? data.outlet.address : ''}
        </div>
        {isActive ? (
          <div className={s.stat_block}>{data.status}</div>
        ) : (
          <div className={s.o_number}>№ {data.order_id ? data.order_id : data.id}</div>
        )}
      </div>
      <div className={s.trigger}>
        <TriggerSvg />
      </div>
    </NavLink>
  )
}

export default React.memo(OrderItem)
