import React, { useEffect, useRef, useState } from 'react'
import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api'
import { useOutlets } from 'application/domain/useCase/outlets/getOutlets'
import locationPng from 'images/donuts_location.png'
import supportedLocationPng from 'images/donuts_location_supported.png'
import vanLocationPng from 'images/van_location.png'
import supportedVanLocationPng from 'images/van_location_supported.png'
import { Outlet } from 'application/domain/entity/outlet/Outlet'

import s from './OutletMiniMap.module.scss'

const INITIAL_REGION = {
  lat: 56.098798 + 0.0015,
  lng: 47.285116,
}

type Props = {
  selectedBar: Outlet | null
  containerClassName?: string
}

const OutletMiniMap = ({ selectedBar, containerClassName }: Props) => {
  const mapRef = useRef<any>()
  const [center, setCenter] = useState<any>(
    selectedBar
      ? {
          lat: selectedBar.width + 0.0015,
          lng: selectedBar.height,
        }
      : INITIAL_REGION,
  )
  const { data: outlets } = useOutlets()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCnZLUtXpncfeigG0PRxueF2e6W8pwycq8',
  })

  useEffect(() => {
    if (mapRef && mapRef.current && selectedBar) {
      setCenter({ lat: selectedBar.width + 0.0015, lng: selectedBar.height, zoom: 13 })
    }
  }, [selectedBar, mapRef])

  return (
    <div className={`${s.container} ${containerClassName || ''}`}>
      {isLoaded && outlets && selectedBar ? (
        <GoogleMap
          mapContainerClassName={s.map_container}
          center={center}
          zoom={14}
          ref={mapRef}
          onCenterChanged={() => {}}
          options={{
            disableDefaultUI: true,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            panControl: false,
            scaleControl: false,
            rotateControl: false,
          }}
        >
          {outlets.map((item: Outlet, index: number) => {
            const image =
              item.type === 'outlet'
                ? item.is_distance_order
                  ? {
                      url: supportedLocationPng,
                      scaledSize: new google.maps.Size(60, 60),
                    }
                  : {
                      url: locationPng,
                      scaledSize: new google.maps.Size(40, 60),
                    }
                : item.is_distance_order
                ? {
                    url: supportedVanLocationPng,
                    scaledSize: new google.maps.Size(60, 60),
                  }
                : {
                    url: vanLocationPng,
                    scaledSize: new google.maps.Size(60, 60),
                  }

            return (
              <MarkerF
                key={`marker_${item.width}_${item.height}_${index}`}
                position={{
                  lat: item.width,
                  lng: item.height,
                }}
                visible={selectedBar && selectedBar.id === item.id ? true : false}
                onVisibleChanged={() => {}}
                icon={image}
              />
            )
          })}
        </GoogleMap>
      ) : (
        <></>
      )}
      <div className={s.overflow}></div>
    </div>
  )
}

export default React.memo(OutletMiniMap)
