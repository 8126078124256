import apiClient from 'application/data/apiClient'
import ToastNotify from 'application/presentation/context/ToastNotify'
import Header from 'application/presentation/common/Header/Header'
import {
  BasketBottomBar,
  OutletMiniMap,
  PaySelector,
  SelectOutletBlock,
} from 'application/presentation/common/uiComponents'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentProps } from 'types/PaymentsType'
import { RootState } from 'application/domain/store/reduxStore'
import { mergeBasketData } from 'application/domain/useCase/basket/basketActions'
import { getSelectedAdditionasIds } from 'application/domain/useCase/products/productsAction'
import {
  checkOutletWorkTime,
  getTechnicalBreak,
} from 'application/domain/useCase/outlets/outletActions'
import SelectIssuingBlock from 'application/presentation/common/uiComponents/SelectIssuingBlock'
import {
  Checkbox,
  UniversalInputComponent,
} from 'application/presentation/common/uiComponents/InputComponents'
import { useNavigate } from 'react-router'
import mainPayProcess from 'application/domain/useCase/payments/MainPayProcess'
import { useBalance } from 'application/domain/useCase/user/getUser'
import { toggleBalance } from 'application/domain/store/basketReducer'
import { getSumWithBonus } from 'application/domain/useCase/basket/balanceAction'
import { useOutlets } from 'application/domain/useCase/outlets/getOutlets'

import { useBasket } from '../BasketPage/useBasket'

import AuthBlock from './components/AuthBlock'
import s from './CreateOrderPage.module.scss'
import PromoBlock from './components/PromoBlock'

const CreateOrderPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { outlet, tableId, user, basketData, selectedPayMethod, token, isBalance } = useSelector(
    ({ userReducer, payReducer, basketReducer }: RootState) => ({
      outlet: userReducer.outlet,
      tableId: userReducer.tableId,
      basketData: basketReducer.basket,
      selectedPayMethod: payReducer.selectedPayMethod,
      user: userReducer.user,
      token: userReducer.token,
      isBalance: basketReducer.isBalance,
    }),
  )
  const { data: balanceData } = useBalance(user)
  const { data: outlets } = useOutlets()
  const [orderIsLoading, setOrderIsLoading] = useState<boolean>(false)
  const [bottomBarHidden, setBottomBarHidden] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [showComment, setShowComment] = useState<boolean>(false)
  const [fromBalance, setFromBalance] = useState<boolean>(false)
  const [promoCodeData, setPromoCodeData] = useState<{ code: string; amount: number }>({
    code: '',
    amount: 0,
  })

  const technicalBreak = useMemo(() => {
    if (outlets.length) {
      return getTechnicalBreak(outlet ? _.find(outlets, { id: outlet.id }) : outlets[0])
    }
    return null
  }, [outlets, outlet])

  const tableNum = useMemo(() => {
    return tableId ? parseInt(tableId, 10) : 0
  }, [tableId])

  const {
    initBasket,
    isLoading: basketIsLoading,
    fullPrice,
    somethingNotInStock,
    calculatedBasket,
    error,
  } = useBasket({ outlet, basket: basketData, token })

  useEffect(() => {
    if (basketData) {
      initBasket()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketData])

  const mergedBasketData = useMemo(() => {
    const merged = mergeBasketData(basketData, calculatedBasket)
    return merged
  }, [basketData, calculatedBasket])

  const onPayStart = useCallback(() => {
    setOrderIsLoading(true)
  }, [])

  const onPaySuccess = useCallback(
    (status: string, response?: any) => {
      if (status === 'success') {
        setOrderIsLoading(false)
        navigate(`/basket/order/result?order_id=${response}`)
      } else {
        setOrderIsLoading(false)
        // console.log(response)
        if (response.redirect) {
          navigate(response.redirect, { replace: true })
        } else {
          // if (
          //   response.externalParams &&
          //   response.externalParams.sbolDeepLink &&
          //   response.externalParams.sbolDeepLink !== 'mockDeepLink'
          // ) {
          //   let isBlocked = false
          //   try {
          //     var win = window.open(response.externalParams.sbolDeepLink)
          //     if (win == null) {
          //       isBlocked = true
          //     }
          //   } catch (e) {
          //     isBlocked = true
          //   }
          //   if (isBlocked) {
          //     const url = response.externalParams.sbolDeepLink.replace('sberpay', 'sbolpay')
          //     window.location.href = url
          //   }
          // } else {
          window.location.href = response.formUrl
          // }
        }
      }
    },
    [navigate],
  )
  const onPayError = useCallback((type?: string, message?: string) => {
    ToastNotify(message ? message : 'Ошибка при совершении платежа')
    setOrderIsLoading(false)
  }, [])

  const payProcess = useCallback(
    (order_id: number, type: string) => {
      const payData = {
        amount: fullPrice,
        ip_address: '',
        card_name: '',
        order_id: order_id,
        selected_pay_method: type === 'balance' || fullPrice === 0 ? 4 : selectedPayMethod,
      }
      const paymentFuncData: PaymentProps = {
        //@ts-ignore
        data: payData,
        onStart: onPayStart,
        onSuccess: onPaySuccess,
        onError: onPayError,
      }
      mainPayProcess(paymentFuncData)
    },
    [fullPrice, selectedPayMethod, onPayStart, onPaySuccess, onPayError],
  )

  const balance: {
    cashback_sum: number
    cards_sum: number
  } = useMemo(() => {
    return balanceData ? balanceData : null
  }, [balanceData])

  const sumWithBonus = useMemo(() => {
    if (outlet && user && mergedBasketData && balance) {
      const sum = getSumWithBonus(fullPrice, outlet, balance, isBalance, user, mergedBasketData)
      return sum
    }
    return {
      cost: 0,
      bonus: 0,
    }
  }, [balance, fullPrice, isBalance, mergedBasketData, outlet, user])

  const createOrder = useCallback(
    async (type: string) => {
      if (!outlet) {
        ToastNotify('Не выбрана точка выдачи')
        return false
      }
      if (outlet && checkOutletWorkTime(outlet)) {
        ToastNotify('Выбранная точка не принимает заказы. Повторите попытку в рабочее время.')
        return false
      }
      if (mergedBasketData && mergedBasketData.length) {
        setOrderIsLoading(true)
        let sendData = {
          outletId: outlet.id,
          table: tableNum,
          orderItems: [],
          paymentType: type === 'balance' || fullPrice === 0 ? 'manual' : 'app',
          comment: comment,
          gift: sumWithBonus.bonus,
        }
        //@ts-ignore
        if (window.ENV && window.ENV.kkmpayment) {
          //@ts-ignore
          sendData.kkmpayment = true
        }

        if (!user) {
          // @ts-ignore
          sendData.client_id = 0
        }
        // @ts-ignore
        if (comment === '') delete sendData.comment

        sendData.orderItems = mergedBasketData.map((item: any) => {
          const volume = _.find(item.varieties, { selected: true })
          return {
            id: item.id,
            temperature: item.temperature,
            variety_id: volume ? volume.variety_id : null,
            options: getSelectedAdditionasIds(item),
          }
        })

        apiClient
          .post('/api/sendDistanceOrder', sendData)
          .then((response: any) => {
            toggleBalance(false)(dispatch)
            if (response.data.status === 'FAIL') {
              ToastNotify(response.data.message)
              setOrderIsLoading(false)
            } else if (response.data.open_order) {
              // setOpenOrderId(response.data.open_order.id)
              //@ts-ignore
              if (window.ENV && window.ENV.kkmpayment) {
                navigate(`/orders/${response.data.open_order.id}?is_open_order=true`)
              } else {
                if (fullPrice === 0) {
                  onPaySuccess('success', response.data.open_order.id)
                } else {
                  payProcess(response.data.open_order.id, type)
                }
              }
            }
          })
          .catch(() => {
            ToastNotify('Произошла ошибка, попробуйте позже')
            setOrderIsLoading(false)
          })
      } else {
        ToastNotify('Корзина пустая!')
      }
    },
    [
      outlet,
      mergedBasketData,
      tableNum,
      fullPrice,
      comment,
      sumWithBonus,
      user,
      dispatch,
      navigate,
      onPaySuccess,
      payProcess,
    ],
  )

  return (
    <div className={s.container}>
      <Header title={`Оформление\nзаказа`} />
      <div className={s.content}>
        <div className={s.title}>Заказ по адресу:</div>
        <div className={s.map}>
          <OutletMiniMap selectedBar={outlet} />
        </div>
        <SelectOutletBlock technicalBreak={technicalBreak} />
        <SelectIssuingBlock />

        {/* <TextInput
          value={outlet ? outlet.name : ''}
          onChange={() => {}}
          readOnly
          rightTxt={` ${tableNum === -1 ? 'На вынос' : `${tableNum} стол`} `}
        /> */}
        <AuthBlock setBottomBarHidden={setBottomBarHidden} user={user} />
        {/* <div className={s.balance_promo_block}>
          <Checkbox
            text={
              <div className={s.balance_checkbox}>
                Списать с баланса <span>500 Р</span>
              </div>
            }
            value={fromBalance}
            onChange={setFromBalance}
          />
          <PromoBlock code={promoCodeData.code} setCode={setPromoCodeData} />
        </div> */}
        {user ? (
          <>
            {showComment ? (
              <UniversalInputComponent
                type="textarea"
                value={comment}
                onChange={setComment}
                placeholder="Введите комментарий"
                title="Комментарий"
                inputContainerClassName={s.comment_input}
              />
            ) : (
              <div className={s.comment} onClick={() => setShowComment(true)}>
                Добавить комментарий?
              </div>
            )}
            <PaySelector isBalance={fullPrice === 0} />
          </>
        ) : (
          <></>
        )}
      </div>
      {user ? (
        <BasketBottomBar
          sumWithBonus={sumWithBonus}
          price={fullPrice}
          somethingNotInStock={somethingNotInStock}
          basketIsLoading={basketIsLoading}
          isPayment
          disabled={!!error || !mergedBasketData.length || !user}
          promoCodeData={promoCodeData}
          onPayBtnClick={createOrder}
          payIsLoading={orderIsLoading}
          hidden={bottomBarHidden}
          containerClassName={s.bottom_bar_container}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(CreateOrderPage)
