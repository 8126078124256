import { RootState } from 'application/domain/store/reduxStore'
import { useOrdersHistory } from 'application/domain/useCase/orders/getOrders'
import Header from 'application/presentation/common/Header/Header'
import { CircleProgressBar } from 'application/presentation/common/uiComponents'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as DashedCircle } from 'images/icons/dashed_circle.svg'
import { ReactComponent as CheckSvg } from 'images/icons/check_icon.svg'
import apiClient from 'application/data/apiClient'
import Loader from 'application/presentation/common/Loader'
import ProductImage from 'application/presentation/common/uiComponents/ProductImage'

import s from './FreeCoffePage.module.scss'
const FreeCoffePage = () => {
  const token = useSelector(({ userReducer }: RootState) => userReducer.token)
  const { freeCup: freeCupData, data: historyData, isLoading } = useOrdersHistory(token || '')

  const freeCup = useMemo(() => {
    return freeCupData || freeCupData === 0 ? freeCupData : 5
  }, [freeCupData])

  const lastFive = useMemo(() => {
    if (historyData.length) {
      const lastProducts = _.filter(
        _.flatten(_.map(historyData, (item) => item.products)),
        (product) => (product.can6 ? true : false),
      )
      return lastProducts
    }
    return []
  }, [historyData])

  const data = useMemo(() => {
    let arr: any = []
    for (let i = 0; i < 5; i++) {
      if (i < 5 - freeCup && lastFive[i]) {
        arr.push(lastFive[i])
      } else {
        arr.push(undefined)
      }
    }
    return arr
  }, [lastFive, freeCup])

  return (
    <div className={s.container}>
      <Header title="Кофе бесплатно" />
      {isLoading ? (
        <Loader size={32} />
      ) : (
        <div className={s.content}>
          <div className={s.txt}>
            Заполняйте стикеры кофем, покупая указывайте свой номер телефона и получайте беспланый
            стакан любимого напитка бесплатно!
          </div>

          <div className={s.coffe_container}>
            <div className={s.head}>
              <div className={s.title}>
                Шестой кофе
                <br />в подарок!
              </div>
              <CircleProgressBar
                color="#FF9E44"
                backgroundColor="#fff"
                procent={((5 - freeCup) / 5) * 100}
                size={'small'}
              >
                <div className={s.count}>{5 - freeCup}</div>
              </CircleProgressBar>
            </div>
            <div className={s.subtitle}>
              Заполняйте стикеры кофем, покупая указывайте свой номер телефона и получайте беспланый
              стакан любимого напитка бесплатно!
            </div>

            <div className={s.coffe_list}>
              {data.map((item: any, i: number) => {
                return (
                  <div className={s.coffet_item_container}>
                    <div className={s.coffe_item} key={`free_coffe_${i}`}>
                      <div className={s.dashed_circle}>
                        <DashedCircle />
                      </div>
                      {item ? (
                        <>
                          <ProductImage
                            containerClassName={s.item_img_container}
                            isDrink={true}
                            imageUrl={item.image}
                          />
                          <div className={s.check}>
                            <CheckSvg />
                          </div>
                        </>
                      ) : (
                        <div className={s.mumber_block}>{i + 1}</div>
                      )}
                    </div>
                  </div>
                )
              })}
              <div className={s.coffet_item_container}>
                <div className={`${s.coffe_item} ${s.last}`}>
                  <div className={s.last_txt}>Бесплатный кофе</div>
                </div>
              </div>
            </div>
          </div>
          <div className={s.rules}>
            Подробные правила акции описаны в пользовательском соглашении. <a href="#">Подробнее</a>
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(FreeCoffePage)
