import React, { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ReactComponent as PacketSvg } from 'images/icons/packet.svg'
import tablePng from 'images/table.png'
import { useDispatch, useSelector } from 'react-redux'
import { setTableId } from 'application/domain/store/userReducer'
import { RootState } from 'application/domain/store/reduxStore'

import BottomSheet from '../BottomSheet'

import s from './SelectIssuingMW.module.scss'

const SelectIssuingMW = () => {
  const { tableId } = useSelector(({ userReducer }: RootState) => ({
    tableId: userReducer.tableId,
  }))
  const savedTableId = sessionStorage.getItem('table_id')
  const dispatch = useDispatch()
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf('select_issuing') !== -1
  }, [location])

  const onItemClick = useCallback(
    (id: string) => {
      setTableId(id)(dispatch)
      navigation(-1)
    },
    [navigation, dispatch],
  )

  return (
    <div>
      <BottomSheet
        opened={opened}
        autoHeight
        onSheetClosed={() => {
          if (location.hash.indexOf('select_issuing') !== -1) {
            navigation(-1)
          }
        }}
      >
        <div className={s.container}>
          <div className={s.mw_title}>Как будем заказывать?</div>
          <div className={s.items}>
            <div className={s.item} onClick={() => onItemClick('0')}>
              <div className={s.center}>
                <div className={s.title}>С собой</div>
              </div>
              <div className={s.img}>
                <PacketSvg />
              </div>
            </div>
            {savedTableId && parseInt(savedTableId, 10) > 0 ? (
              <div className={s.item} onClick={() => onItemClick(savedTableId)}>
                <div className={s.center}>
                  <div className={s.title}>В зале</div>
                  <div className={s.subtitle}>Стол №{savedTableId}</div>
                </div>
                <div className={s.img}>
                  <img src={tablePng} alt="" />
                </div>
              </div>
            ) : !tableId || (tableId && parseInt(tableId, 10) <= 0) ? (
              <div className={s.item} onClick={() => onItemClick('-2')}>
                <div className={s.center}>
                  <div className={s.title}>В зале</div>
                  <div className={s.subtitle}>На свободный стол</div>
                </div>
                <div className={s.img}>
                  <img src={tablePng} alt="" />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </BottomSheet>
    </div>
  )
}

export default React.memo(SelectIssuingMW)
