import { HeadProvider } from 'react-head'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from 'application/data/apiClient/queryClient'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
//@ts-ignore
import Framework7 from 'framework7/lite-bundle'
import Framework7React, { App as Framework7App, View } from 'framework7-react'
import moment from 'moment'
import store from 'application/domain/store/reduxStore'
import { Authorization as AuthProvider } from 'application/presentation/context/Authorization'

import { ErrorHandler } from './ErrorBoundary'
import AppView from './AppView'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/ru'
import './app.scss'

moment.locale('ru')
Framework7.use(Framework7React)

const f7params = {
  // Array with app routes
  // routes,
  // App Name
  name: 'DonDonuts',
  // App id
  id: 'com.myapp.test',
  theme: 'ios',
  // ...
  clicks: {
    externalLinks: 'a[href^="/"], a[href^="http"]', // all links as external, this is to enable normal behavior
  },
}

function App() {
  return (
    <ErrorHandler>
      {/* @ts-ignore */}
      <HeadProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Provider store={store}>
              <AuthProvider>
                <Framework7App {...f7params}>
                  <View main>
                    <AppView />
                  </View>
                </Framework7App>
              </AuthProvider>
            </Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </HeadProvider>
    </ErrorHandler>
  )
}

export default App
