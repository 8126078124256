import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as SberpaySvg } from 'images/payIcons/sberpay2.svg'
import { ReactComponent as CardSvg } from 'images/payIcons/card_icon.svg'
import { ReactComponent as TriggerSvg } from 'images/icons/right_gray_trigger.svg'
import { SelectPayMethodMW } from 'application/presentation/common/ModalWindows'
import { useNavigate } from 'react-router'
import { RootState } from 'application/domain/store/reduxStore'
import { useUserPaymentCards } from 'application/domain/useCase/payments/userPaymentsCard'
import { ReactComponent as MirSvg } from 'images/payIcons/mir.svg'
import { ReactComponent as VisaSvg } from 'images/payIcons/visa.svg'
import { ReactComponent as MasterCardSvg } from 'images/payIcons/mastercard.svg'
import _ from 'lodash'
import { UserPaymentCard } from 'application/domain/entity/userCards/UserPaymentCard'

import s from './PaySelector.module.scss'

type Props = {
  isBalance?: boolean
}

const PaySelector = ({ isBalance }: Props) => {
  const { token } = useSelector(({ userReducer }: RootState) => ({
    token: userReducer.token,
  }))
  const navigation = useNavigate()
  const { selectedPayMethod } = useSelector(({ payReducer }: RootState) => ({
    selectedPayMethod: payReducer.selectedPayMethod,
  }))
  const { data: cards } = useUserPaymentCards(token)

  const payItemData = useMemo(() => {
    if (isBalance) {
      return {
        name: 'Коф.карта',
        icon: <></>,
      }
    }
    switch (selectedPayMethod) {
      case 1:
        return {
          name: 'Sberpay',
          icon: <SberpaySvg />,
        }
      case 5:
        return {
          name: 'Новая карта',
          icon: <CardSvg />,
        }
      case 4:
        return {
          name: 'Коф.карта',
          icon: <></>,
        }
      default:
        break
    }
    if (cards) {
      const card = _.find(cards, (c: UserPaymentCard) => c.id === selectedPayMethod)
      let img = <></>
      if (card && card.card_number) {
        switch (card.payment_system) {
          case 'MIR':
            img = <MirSvg />
            break
          case 'MASTERCARD':
            img = <MasterCardSvg />
            break
          case 'VISA':
            img = <VisaSvg />
            break
          default:
            img = <CardSvg />
        }
        return {
          name: '',
          icon: img,
          card: `${card.payment_system ? card.payment_system : ''} **${card.card_number.replace(
            /X/g,
            '',
          )}`,
        }
      }
    }
    return {
      name: '',
      icon: <></>,
    }
  }, [selectedPayMethod, cards, isBalance])

  const openSelectPayMethod = useCallback(
    () => (!isBalance ? navigation('#pay_method') : {}),
    [navigation, isBalance],
  )

  return (
    <>
      <div className={s.out_container}>
        <div className={s.title}>Оплата</div>
        <div className={s.container} onClick={openSelectPayMethod}>
          <div className={s.icon}>{payItemData.icon}</div>
          <div className={s.name}>{payItemData.card ? payItemData.card : payItemData.name}</div>
          <div className={s.trigger}>
            <TriggerSvg />
          </div>
        </div>
      </div>
      <SelectPayMethodMW />
    </>
  )
}

export default React.memo(PaySelector)
