import apiClient from 'application/data/apiClient'
import React, { useCallback, useMemo } from 'react'
import { ProductType } from 'types/catalogType'
import { ReactComponent as PlusSvg } from 'images/icons/orange_circle_plus.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { Element as ScrollElement } from 'react-scroll'
import { getProductPrice, Product } from 'application/domain/entity/product/Product'
import ProductImage from 'application/presentation/common/uiComponents/ProductImage'

import s from '../MainPage.module.scss'

type Props = {
  data: Array<any>
}

const ProductItem = React.memo(({ product }: { product: Product }) => {
  const navigate = useNavigate()
  const price = useMemo(() => getProductPrice(product), [product])

  const addProductToBasket = useCallback(
    (e: any) => {
      e.preventDefault()
      navigate(`/product/${product.id}`)
    },
    [product, navigate],
  )

  return (
    <NavLink className={s.product_item} to={`/product/${product.id}`}>
      <ProductImage
        containerClassName={s.product_img}
        imageUrl={product.image}
        isDrink={product.isDrink}
      />
      <div className={s.product_name}>{product.product_name}</div>
      <div className={s.add_btn_container}>
        <button className={s.add_btn} onClick={addProductToBasket}>
          <PlusSvg />
          <span>{price} ₽</span>
        </button>
      </div>
    </NavLink>
  )
})

const ProductsRow = React.memo(({ data }: { data: Array<any> }) => {
  return (
    <div className={s.row}>
      {data[0] ? <ProductItem product={data[0]} /> : <></>}
      {data[1] ? <ProductItem product={data[1]} /> : <></>}
    </div>
  )
})

const ProductsList = ({ data }: Props) => {
  return (
    <div className={s.products_list}>
      {data.map((item: any, i: number) => {
        return (
          <ScrollElement name={`category_${item.id}`} key={`category_title_${item.title}`}>
            <div className={s.category_title}>{item.title}</div>
            {item.products ? (
              item.products.map((p: Array<ProductType>, j: number) => (
                <ProductsRow data={p} key={`product_item_row_${j}`} />
              ))
            ) : (
              <></>
            )}
          </ScrollElement>
        )
      })}
    </div>
  )
}

export default React.memo(ProductsList, (prev, next) => _.isEqual(prev.data, next.data))
