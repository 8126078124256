import apiClient from 'application/data/apiClient'
import React, { useCallback, useMemo } from 'react'
import { ReactComponent as OrangePlusSvg } from 'images/icons/orange_plus.svg'
import { ReactComponent as PlusSvg } from 'images/icons/plus.svg'
import { ReactComponent as MinusSvg } from 'images/icons/minus.svg'
import _ from 'lodash'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { Product } from 'application/domain/entity/product/Product'
import { addProductToBasket } from 'application/domain/useCase/basket/addToBasket'
import {
  removeProductFromBasket,
  removeProductsFromBasket,
} from 'application/domain/useCase/basket/removeFromBasket'
import { ProductOption } from 'application/domain/entity/product/ProductOption'
import ProductImage from 'application/presentation/common/uiComponents/ProductImage'

import s from './BasketList.module.scss'

type BasketListProps = {
  data: Array<Product>
  basketIsLoading: boolean
}

type BasketItemProps = {
  data: Product
  basketIsLoading: boolean
}

const BasketItem = React.memo(({ data, basketIsLoading }: BasketItemProps) => {
  const dispatch = useDispatch()
  const selectedAdditionals = useMemo(() => {
    let arr: any = []
    if (data) {
      let temp = _.filter(data.selected_options, { selected: true })
      temp.map((item: any) => {
        item.items.map((item2: any) => {
          if (item2.selected) {
            arr.push(item2.name)
          }
        })
      })
    }
    return arr.length ? _.join(arr, ', ') : ''
  }, [data])
  const selectedVolume = useMemo(() => {
    if (data && data.variety) {
      return data.variety.name ? data.variety.name : ''
    } else if (data && data.varieties) {
      const volume = _.find(data.varieties, { selected: true })
      return volume ? volume.name : ''
    }
    return ''
  }, [data])

  const optionNotAvailable = useMemo(() => {
    let notAvailable = false
    if (data) {
      let temp = _.filter(data.options, { selected: true })
      temp.map((item: ProductOption) => {
        const f = _.find(item.items, (o) => {
          return (
            //@ts-ignore
            o.selected === true &&
            (o.availability_status === 'not_available' || o.availability_status === 'ended')
          )
        })
        if (f) {
          notAvailable = true
        }
      })
    }
    return notAvailable
  }, [data])

  const plusClickHandler = useCallback(() => {
    const volume = _.find(data.varieties, { selected: true })
    return addProductToBasket(data, {
      selected_temperature: data.temperature === 'warm' ? 1 : 2,
      selected_volume: volume ? volume.id : 0,
      callback: () => {
        // navigation.goBack()
        // toastContext.show(`${dat.product_name} добавлен`)
      },
    })(dispatch)
  }, [dispatch, data])

  const minusClickHandler = useCallback(
    () => removeProductFromBasket(data)(dispatch),
    [data, dispatch],
  )

  const removeClickHandler = useCallback(
    () => removeProductsFromBasket(data.uniq_ids)(dispatch),
    [dispatch, data],
  )

  return (
    <div className={s.item}>
      <ProductImage
        containerClassName={s.img_container}
        isDrink={data.isDrink}
        imageUrl={data.image}
      />
      <div className={s.item_content}>
        <div className={s.info}>
          <div className={s.left}>
            <div className={s.title}>{data.product_name}</div>
            {selectedVolume ? (
              <div className={s.item_volume}>
                {selectedVolume}{' '}
                {data.temperature ? `(${data.temperature === 'warm' ? 'Теплый' : 'Горячий'})` : ''}
              </div>
            ) : (
              <></>
            )}
            {data && data.variety && data.variety.cost === 0 ? (
              <div className={s.free_coffe}>Бесплатный кофе</div>
            ) : null}
            <div className={s.additionals}>
              {selectedAdditionals ? (
                <div className={s.additionals_item}>
                  <div className={s.additionals_plus}>
                    <OrangePlusSvg />
                  </div>
                  <div className={s.list}>{selectedAdditionals}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={cn(s.right, basketIsLoading ? s.gray : '')}>
            {data.availability_status === 'in_stock' && !optionNotAvailable
              ? `${data.full_cost ? data.full_cost : 0} ₽`
              : 'Нет в наличии'}
          </div>
        </div>
        <div className={s.control}>
          <div></div>
          {/* <div className={s.control_btn_container}>
            <div className={s.control_txt}>Редактировать</div>
          </div> */}
          {data.discont && data.discont > 0 ? (
            <div className={s.control_btn_container} onClick={minusClickHandler}>
              <div className={s.control_txt}>Удалить</div>
            </div>
          ) : (
            <div className={s.control_btn_container}>
              <div className={s.control_item} onClick={minusClickHandler}>
                <MinusSvg />
              </div>
              <div className={s.control_item}>{data.count}</div>
              <div className={s.control_item} onClick={plusClickHandler}>
                <PlusSvg />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

const BasketList = ({ data, basketIsLoading }: BasketListProps) => {
  return (
    <div className={s.basket_list}>
      {data ? (
        data.map((item) => (
          <BasketItem
            data={item}
            key={`basket_item_${item.uniq_id}`}
            basketIsLoading={basketIsLoading}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(BasketList)
