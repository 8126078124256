import { useCallback, useState } from 'react'
import apiClient from 'application/data/apiClient'
import { Order } from 'application/domain/entity/order/Order'
import mainPayProcess from 'application/domain/useCase/payments/MainPayProcess'
import { useNavigate } from 'react-router'

export type TipsDataType = {
  variables: Array<string>
  comment: string
  sum: number
  withPayment: boolean
  selected_pay_method: number
}

type Props = {
  order?: Order
  setSberOrderId: Function
  setPaymentCheckStage: Function
  setTipsWithPayment: Function
  tipsWithPayment: boolean
  setRating: Function
  setIsSuccess: Function
  setRated: Function
  rating: number
}

export const useTipsPayment = ({
  order,
  setSberOrderId,
  setPaymentCheckStage,
  tipsWithPayment,
  setRating,
  setTipsWithPayment,
  setIsSuccess,
  rating,
  setRated,
}: Props) => {
  const [tipsSaveIsLoading, setSaveTipsIsLoading] = useState<boolean>(false)

  const onPaySuccess = useCallback(
    (stat: string, response?: any) => {
      console.log(response)
      if (stat === '3ds') {
        if (response && response.externalParams && response.externalParams.sbolDeepLink) {
          window.location.href = response.externalParams.sbolDeepLink
        } else {
          window.location.href = response.formUrl
        }
        setPaymentCheckStage(true)
        setSberOrderId(response.orderId)
      } else if (stat === 'success') {
        setSaveTipsIsLoading(false)
        setIsSuccess(true)
        // navigate(`${window.location.href}#result`, { replace: true })
      } else {
        if (response) {
          const urlParams = new URLSearchParams(response.redirect.split('?')[1])
          setSberOrderId(urlParams.get('orderId'))
          setSaveTipsIsLoading(false)
          setPaymentCheckStage(true)
        }
      }
    },
    [setPaymentCheckStage, setSberOrderId, setIsSuccess],
  )

  const onPayError = useCallback((type?: string, message?: string) => {
    console.log('payError')
    setSaveTipsIsLoading(false)
  }, [])

  const createTips = useCallback(
    (tipsData: TipsDataType) => {
      setSaveTipsIsLoading(true)
      const comment = tipsData.withPayment
        ? `Комментарий: ${tipsData.comment}`
        : `Не понравилось: ${tipsData.variables.join(', ')}; ${
            tipsData.comment ? `Комментарий: ${tipsData.comment}` : ''
          }`
      apiClient
        .post('/api/rating', {
          order_id: order?.id ? order.id : order?.order_id,
          rating: rating,
        })
        .then(() => {
          apiClient
            .post('/api/tips', {
              order_id: order?.id ? order.id : order?.order_id,
              sum: tipsData.sum,
              comment: comment,
            })
            .then((response) => {
              if (tipsData.withPayment) {
                setTipsWithPayment(true)
                const payData = {
                  data: {
                    tips_id: response.data.id,
                    selected_pay_method: tipsData.selected_pay_method,
                    order_id: order?.id ? order.id : order?.order_id,
                  },
                  onStart: () => {},
                  onSuccess: onPaySuccess,
                  onError: onPayError,
                }

                //@ts-ignore
                mainPayProcess(payData)
              } else {
                setTipsWithPayment(false)
                setSaveTipsIsLoading(false)
                setIsSuccess(true)
              }
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                console.log(error.response.data)
              }
              setSaveTipsIsLoading(false)
            })
        })
    },
    [order, rating, setTipsWithPayment, onPaySuccess, onPayError, setIsSuccess],
  )

  const onBackPress = useCallback(() => {
    setSaveTipsIsLoading(false)
    setPaymentCheckStage(false)
  }, [setPaymentCheckStage])

  const rateOrder = useCallback(
    (rate: number) => {
      if (!order?.rating || order?.rating === 0) {
        setRating(rate)
        setRated(true)
      }
    },
    [order, setRating, setRated],
  )

  return {
    createTips,
    tipsSaveIsLoading,
    tipsWithPayment,
    onBackPress,
    onPaySuccess,
    rateOrder,
  }
}
