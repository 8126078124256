import React, { useCallback } from 'react'
import { ReactComponent as CheckSvg } from 'images/icons/check_icon.svg'

import s from './Checkbox.module.scss'

type Props = {
  text: string | React.ReactNode
  value: boolean
  onClick?: Function
  onChange?: Function
  containerClassName?: string
  textClassName?: string
}

const Checkbox = ({ text, value, onChange, containerClassName, onClick, textClassName }: Props) => {
  const clickHandler = useCallback(() => {
    if (onClick) {
      return onClick()
    }
    if (onChange) {
      return onChange(!value)
    }
  }, [onClick, onChange, value])

  return (
    <div className={`${s.container} ${containerClassName || ''}`} onClick={clickHandler}>
      <div className={`${s.text} ${textClassName || ''}`}>{text}</div>
      <div className={`${s.checkbox} ${value ? s.checked : ''}`}>{value && <CheckSvg />}</div>
    </div>
  )
}

export default React.memo(Checkbox)
