import React, { useCallback, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { CategoryType } from 'types/catalogType'
import { Link, scroller } from 'react-scroll'

import s from '../MainPage.module.scss'

type CategoryChipsProps = {
  data: Array<CategoryType>
}

type ChipsItemProps = {
  item: CategoryType
  handleSetActive: any
  activeChip: number
}

const ChipsItem = React.memo(({ item, handleSetActive, activeChip }: ChipsItemProps) => {
  return (
    <Link
      className={cn(s.chips_item, activeChip === item.id ? s.active : '')}
      spy={true}
      name={`link_${item.id}`}
      to={`category_${item.id}`}
      id={`category_${item.id}`}
      smooth={true}
      containerId={'main_scroll'}
      offset={-50}
      onSetActive={() => handleSetActive(item.id)}
    >
      {item.name}
    </Link>
  )
})

const CategoryChips = ({ data }: CategoryChipsProps) => {
  const scrollRef = useRef<any>(null)
  const [activeChip, setActiveChip] = useState<number>(data && data[0] ? data[0].id : 0)
  const handleSetActive = useCallback((id: number) => {
    setActiveChip(id)
    scroller.scrollTo(`link_${id}`, {
      containerId: 'chips_scroll',
      smooth: true,
      horizontal: true,
      offset: -25,
      duration: 500,
    })
  }, [])
  return (
    <div className={s.chips_container} ref={scrollRef} id="chips_scroll">
      {data.map((item) => (
        <ChipsItem
          item={item}
          key={`category_chips_${item.id}`}
          handleSetActive={handleSetActive}
          activeChip={activeChip}
        />
      ))}
    </div>
  )
}

export default React.memo(CategoryChips)
