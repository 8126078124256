import { RootState } from 'application/domain/store/reduxStore'
import Header from 'application/presentation/common/Header/Header'
import { PayButtons, PaySelector, TextInput } from 'application/presentation/common/uiComponents'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _find from 'lodash/find'
import { useSberPaymentStatus } from 'application/domain/useCase/payments/getPaymentStatus'
import { useLocation, useNavigate } from 'react-router'
import PaymentCheckView from 'application/presentation/common/Payments/PaymentCheckView'

import SuccessOrderRate from '../OrderPage/components/SuccessOrderRate'
import AuthBlock from '../CreateOrderPage/components/AuthBlock'

import s from './OutletTipsPage.module.scss'
import { OutletTipsDataType, useOutletTipsPayment } from './useOutletTipsPayment'

const tipsItems = [
  {
    id: 1,
    name: 'Другая\nсумма',
    sum: 0,
  },
  {
    id: 2,
    name: '15 ₽',
    sum: 15,
  },
  {
    id: 3,
    name: '30 ₽',
    sum: 30,
  },
  {
    id: 4,
    name: '50 ₽',
    sum: 50,
  },
]

type CreateTipProps = {
  withPayment: boolean
  selectedTip?: number
  selectedVariables?: Array<string>
}

const OutletTipsPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedPayMethod, outlet, user } = useSelector(
    ({ payReducer, userReducer }: RootState) => ({
      selectedPayMethod: payReducer.selectedPayMethod,
      outlet: userReducer.outlet,
      user: userReducer.user,
    }),
  )
  const [customSum, setCustomSum] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [selectedTip, setSelectedTip] = useState<number>(2)
  const [paymentCheckStage, setPaymentCheckStage] = useState<boolean>(false)
  const [sberOrderId, setSberOrderId] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const orderId = params.get('orderId')
    if (orderId) {
      setSberOrderId(orderId)
      setPaymentCheckStage(true)
    }
  }, [location.search])

  const { createTips, tipsSaveIsLoading, onBackPress, onPaySuccess } = useOutletTipsPayment({
    setPaymentCheckStage,
    setSberOrderId,
    setIsSuccess,
  })

  const { data: paymentStatusData } = useSberPaymentStatus(sberOrderId)

  const create = useCallback(() => {
    const tip = _find(tipsItems, { id: selectedTip })
    const sum = selectedTip === 1 ? (customSum ? parseInt(customSum, 10) : 0) : tip ? tip.sum : 0
    const sendData: OutletTipsDataType = {
      sum,
      comment,
      selected_pay_method: selectedPayMethod,
      outlet_id: outlet ? outlet.id : 0,
    }

    return createTips(sendData)
  }, [selectedTip, customSum, comment, selectedPayMethod, createTips, outlet])

  useEffect(() => {
    if (paymentCheckStage) {
      if (paymentStatusData && paymentStatusData.errorMessage === 'Успешно') {
        onPaySuccess('success')
      }
    }
  }, [paymentStatusData, paymentCheckStage, onPaySuccess])

  const closePage = useCallback(() => {
    navigate('/', { replace: true })
  }, [navigate])

  return (
    <div className={s.container}>
      <Header title={`Чаевые`} onBackPress={closePage} />

      <div className={s.content}>
        {isSuccess ? (
          <SuccessOrderRate
            rating={5}
            tipsWithPayment={true}
            baristaName={'Бариста'}
            closePage={closePage}
          />
        ) : paymentCheckStage ? (
          <PaymentCheckView onBackPress={onBackPress} />
        ) : (
          <>
            <div className={s.input_container}>
              <TextInput
                value={comment}
                onChange={(e: any) => setComment(e.target.value)}
                placeholder="Вы можете оставить комментарий"
              />
            </div>
            <div className={s.tips_container}>
              {tipsItems.map((item) => (
                <div
                  className={`${s.tips_item} ${item.id === selectedTip ? s.active : ''}`}
                  key={`tips_item_${item.id}`}
                  onClick={() => setSelectedTip(item.id)}
                >
                  <div className={`${s.tips_txt} ${item.id === selectedTip ? s.txt_active : ''}`}>
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
            {selectedTip === 1 && (
              <UniversalInputComponent
                type="number"
                title="Введите сумму чаевых для баристы"
                value={customSum}
                onChange={setCustomSum}
                placeholder="Введите сумму"
              />
            )}
            <AuthBlock setBottomBarHidden={() => {}} user={user} />
            <PaySelector />
            <PayButtons
              containerStyle={s.btn_container}
              isLoading={tipsSaveIsLoading}
              onClick={create}
              disabled={!user ? true : false}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(OutletTipsPage)
