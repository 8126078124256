import React, { useCallback, useMemo, useState } from 'react'
import { ReactComponent as RingSvg } from 'images/icons/ring.svg'
import { ReactComponent as CoinsSvg } from 'images/icons/coins.svg'
import ToastNotify from 'application/presentation/context/ToastNotify'
import { callPersonal } from 'application/domain/useCase/user/getUser'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'
import Loader from 'application/presentation/common/Loader'
import { useNavigate } from 'react-router'

import s from './ControllBlock.module.scss'

const ControllBlock = () => {
  const navigate = useNavigate()
  const { outlet, tableId } = useSelector(({ userReducer }: RootState) => ({
    outlet: userReducer.outlet,
    tableId: userReducer.tableId,
  }))
  const [callIsLoading, setCallIsLoading] = useState<boolean>(false)

  const showCallPersonal = useMemo(
    () => (tableId && parseInt(tableId, 10) >= 1 ? true : false),
    [tableId],
  )

  const sendTipsHandler = useCallback(() => navigate('/tips'), [navigate])
  const sendCallPersonal = useCallback(() => {
    setCallIsLoading(true)
    callPersonal({
      outlet_id: outlet?.id || 0,
      table_id: parseInt(tableId || '0', 10),
    })
      .then(() => {
        ToastNotify('Официант скоро подойдет')
        setCallIsLoading(false)
      })
      .catch(() => {
        setCallIsLoading(false)
      })
  }, [outlet, tableId])

  return (
    <div className={s.container}>
      {/* {showCallPersonal && (
        <div className={s.btn} onClick={sendCallPersonal}>
          {callIsLoading ? (
            <Loader size={16} color="#000" containerClassName={s.loader} />
          ) : (
            <>
              <RingSvg />
              <div className={s.btn_title}>Позвать официанта</div>
            </>
          )}
        </div>
      )} */}

      <div className={s.btn} onClick={sendTipsHandler}>
        <CoinsSvg />
        <div className={s.btn_title}>Оставить чаевые</div>
      </div>
    </div>
  )
}

export default React.memo(ControllBlock)
