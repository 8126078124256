import Loader from 'application/presentation/common/Loader'
import { Button, PayButtons } from 'application/presentation/common/uiComponents'
import React, { useMemo } from 'react'
import cn from 'classnames'
import _ from 'lodash'

import s from './BasketBottomBar.module.scss'

type Props = {
  somethingNotInStock: boolean
  price: number
  basketIsLoading: boolean
  promoCodeData?: {
    code: string
    amount: number
  }
  isPayment?: boolean
  onPayBtnClick?: Function
  payIsLoading?: boolean
  hidden?: boolean
  disabled?: boolean
  containerClassName?: string
  sumWithBonus?: {
    cost: number
    bonus: number
  }
}

const BasketBottomBar = ({
  price,
  basketIsLoading,
  somethingNotInStock,
  isPayment,
  onPayBtnClick,
  payIsLoading,
  hidden,
  disabled,
  containerClassName,
  promoCodeData,
  sumWithBonus,
}: Props) => {
  const orderPrice = useMemo(() => {
    return promoCodeData && promoCodeData.amount && price > 0 ? price - promoCodeData.amount : price
  }, [price, promoCodeData])
  return (
    <div className={cn(s.container, containerClassName || '', hidden ? s.hidden : '')}>
      {promoCodeData && promoCodeData.code ? (
        <>
          <div className={s.info}>
            <div className={s.left}>Сумма заказа</div>
            <div className={cn(s.right, s.thin)}>
              {basketIsLoading ? (
                <Loader color="#000" size={16} containerClassName={s.loader} />
              ) : (
                <>{price.toFixed(2)} Р</>
              )}
            </div>
          </div>
          <div className={s.info}>
            <div className={s.left}>Промокод</div>
            <div className={cn(s.right, s.thin)}>{promoCodeData.amount.toFixed(2)} Р</div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className={s.info}>
        <div className={s.left}>Итого</div>
        <div className={s.right}>
          {basketIsLoading ? (
            <Loader color="#000" size={16} containerClassName={s.loader} />
          ) : (
            <>
              {`${_.isNaN(sumWithBonus?.cost) ? 0 : sumWithBonus?.cost.toFixed(2)} ₽`}{' '}
              {sumWithBonus?.bonus ? `(${Number(sumWithBonus?.bonus).toFixed(2)} ₽ бонусами)` : ''}
            </>
          )}
        </div>
      </div>

      {isPayment ? (
        <PayButtons
          onClick={(type: string) => (onPayBtnClick ? onPayBtnClick(type) : {})}
          isLoading={payIsLoading}
          disabled={somethingNotInStock || basketIsLoading || disabled}
        />
      ) : (
        <Button
          text="Оформить заказ"
          isLink
          to="/basket/order"
          color="orange"
          disabled={somethingNotInStock || basketIsLoading || disabled}
          containerClassName={s.btn}
        />
      )}
    </div>
  )
}

export default React.memo(BasketBottomBar)
