import Header from 'application/presentation/common/Header/Header'
import { Button, CodeInput, ErrorMsg } from 'application/presentation/common/uiComponents'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import ToastNotify from 'application/presentation/context/ToastNotify'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import s from './AuthorizationPage.module.scss'
import { useAuthorization } from './useAuthorization'

const PasswordResetPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [seconds, setSeconds] = useState<number>(60)
  const [password, setPassword] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const phone = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('phone') || ''
  }, [location.search])

  const { errMsg, checkIsLoading, regIsLoading, changePassword, timer, sendCode, btnHidden } =
    useAuthorization({
      setSeconds,
    })

  useEffect(() => {
    if (seconds > 0) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        setSeconds(seconds - 1)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  const send = useCallback(
    () =>
      code.length < 4
        ? sendCode(phone, true)
        : changePassword(phone, password, confirmPassword, code, () => {
            navigate('/')
            ToastNotify('Пароль изменен!')
          }),
    [code, sendCode, phone, changePassword, password, confirmPassword, navigate],
  )

  return (
    <div className={s.container}>
      <Header title={'Восстановление пароля'} />

      <div className={s.content}>
        <div className={s.content_title}>
          Вам поступит звонок на номер +{phone}. Введите последние 4 цифры номера, далее придумайте
          новый пароль и повторите его еще раз.
        </div>
        <CodeInput phone={phone || ''} onSuccess={() => {}} controlled={true} onChange={setCode} />

        <CodeInput
          controlled
          onChange={setPassword}
          title={'Теперь придумайте пин-код для входа в приложение'}
          containerClassName={s.pass_pin}
        />
        <CodeInput
          controlled
          onChange={setConfirmPassword}
          title={'Повторите пин'}
          containerClassName={s.pass_pin}
        />
        {errMsg ? <ErrorMsg text={errMsg} containerStyle={s.errmsg} /> : <></>}
      </div>
      <Button
        color="orange"
        text={
          code.length < 4
            ? `Отправить повторно ${seconds ? `(${seconds} сек.)` : ''}`
            : 'Сменить пароль'
        }
        isLoading={checkIsLoading || regIsLoading}
        disabled={code.length < 4 && seconds > 0}
        onClick={send}
        containerClassName={s.btn_container}
      />
    </div>
  )
}

export default React.memo(PasswordResetPage)
