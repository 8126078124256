import Header from 'application/presentation/common/Header/Header'
import { Button } from 'application/presentation/common/uiComponents'
import React from 'react'
import catLeftPng from 'images/flex_cat_left.png'
import catRightPng from 'images/flex_cat_right.png'
import { ReactComponent as VkSvg } from 'images/icons/vk_orange.svg'
import { ReactComponent as FbSvg } from 'images/icons/fb_orange.svg'
import { ReactComponent as InstSvg } from 'images/icons/inst_orange.svg'
import { ReactComponent as McSvg } from 'images/payIcons/cards2.svg'

import s from './ContactsPage.module.scss'

const ContactsPage = () => {
  return (
    <div className={s.container}>
      <Header title="Контакты" />
      <div className={s.content}>
        <div className={s.chat_container}>
          <div className={s.title}>Остались вопросы?</div>
          <div className={s.subtitle}>
            Напишите нам в онлайн-чат и мы ответим на все ваши вопросы
          </div>
          <Button
            text="Написать в чат"
            onClick={() =>
              (window.location.href =
                'https://api.whatsapp.com/send/?phone=%2B79276680200&text&app_absent=0')
            }
            color="orange"
            containerClassName={s.btn_container}
            className={s.btn}
          />
        </div>

        <div className={s.join_container}>
          <div className={s.cat_block}>
            <img src={catLeftPng} alt="" />
            <img src={catRightPng} alt="" />
          </div>
          <div className={s.join_txt}>
            <div className={s.title}>Присоединяйтесь к нам в социальных сетях</div>
            <div className={s.subtitle}>
              Подпишитесь на нас в социальных сетях и будьте в курсе новинок, скидок и акций
            </div>

            <div className={s.social_block}>
              <a href="https://www.instagram.com/dondonuts21/?hl=ru">
                <InstSvg />
              </a>
              <a href="https://www.facebook.com/Don-Donuts-%D0%9A%D0%BE%D1%84%D0%B5-%D0%B8-%D0%BF%D0%BE%D0%BD%D1%87%D0%B8%D0%BA%D0%B8-105128935154761/">
                <FbSvg />
              </a>
              <a href="https://vk.com/dondonuts21">
                <VkSvg />
              </a>
            </div>
          </div>
        </div>
        <a className={s.policy} href="https://dondonuts.ru/policy">
          Политика конфиденциальности
        </a>
        <a className={s.policy} href="https://dondonuts.ru/offer">
          Публичная оферта
        </a>
        <div className={s.policy}>
          Контакты:{'\n'}
          Телефоны: +7 (927) 668 02 00{'\n'}
          Факт. адрес: 428000, Чувашская Республика,{'\n'}
          г.Чебоксары, ул.Калинина, 101{'\n'}
          {'\n'}
          Реквизиты:{'\n'}
          ИП СЕРГЕЕВ НИКОЛАЙ ПЕТРОВИЧ{'\n'}
          ИНН 212711586772 / ОГРИП 312213024400011{'\n'}
          Юр. адрес: г. Чебоксары, ул. Пролетарская 21/22 кВ.51{'\n'}
          Телефон: +79051991699
        </div>
        <div className={s.policy}>
          Способы оплаты:{'\n'}
          Банковской картой: Для выбора оплаты товара с помощью банковской карты на соответствующей
          странице необходимо нажать кнопку Оплата заказа банковской картой. Оплата происходит через
          ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:
        </div>
        <div className={s.mc_icon}>
          <McSvg />
        </div>
        <div className={s.policy}>
          Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО
          СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом
          режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает
          технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard
          SecureCode, MIR Accept, J-Secure, для проведения платежа также может потребоваться ввод
          специального пароля. Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
          сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не
          будет предоставлена третьим лицам за исключением случаев, предусмотренных
          законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом
          соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB
        </div>
      </div>
    </div>
  )
}

export default React.memo(ContactsPage)
