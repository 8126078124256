import apiClient from 'application/data/apiClient'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { OptionItemType } from 'types/catalogType'
import cn from 'classnames'

import BottomSheet from '../BottomSheet'
import ProductImage from '../../uiComponents/ProductImage'

import s from './SelectAdditionalMW.module.scss'

type Props = {
  data: any
  selectedVolume: number
}

const SelectAdditionalMW = ({ data, selectedVolume }: Props) => {
  const [selected, setSelected] = useState<number>(1)
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf('additionals') !== -1
  }, [location])

  useEffect(() => {
    if (data && data.data) {
      let temp = _.find(data.data, { selected: true })
      setSelected(temp ? temp.id : data.data[0] ? data.data[0].id : 0)
    }
  }, [data])

  const select = useCallback(
    (id: number) => {
      if (data.selectAdditional) {
        // data.selectAdditional(selected)
        data.selectAdditional(id)
        navigation(-1)
      }
    },
    [data, navigation],
  )

  return (
    <div>
      <BottomSheet
        opened={opened}
        // autoHeight
        onSheetClosed={() => {
          if (location.hash.indexOf('additionals') !== -1) {
            navigation(-1)
          }
        }}
      >
        <div className={s.container}>
          <div className={s.title}>{data ? data.title : ''}</div>
          <div className={s.flex_container}>
            {data && data.data ? (
              data.data.map((item: OptionItemType, i: number) => {
                let defined = false

                if (item?.item_to_variety?.length) {
                  item.item_to_variety.forEach((variety: any) => {
                    if (variety.product_variety_id === selectedVolume) {
                      defined = true
                    }
                  })
                } else if (_.isEmpty(item.item_to_variety.length)) {
                  defined = true
                }

                if (defined) {
                  return (
                    <div
                      className={cn(s.item)}
                      key={`additional_item_${item.id}`}
                      onClick={() =>
                        item.availability_status === 'in_stock' ? select(item.id) : {}
                      }
                    >
                      <ProductImage containerClassName={s.img_container} imageUrl={item.image} />
                      <div className={s.name}>{item.name}</div>
                      <div className={s.price}>
                        {item.availability_status === 'in_stock'
                          ? item.cost
                            ? `+ ${item.cost} ₽`
                            : 'Бесплатно'
                          : 'Закончился'}
                      </div>
                    </div>
                  )
                }
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </BottomSheet>
    </div>
  )
}

export default React.memo(SelectAdditionalMW)
