/* eslint-disable @typescript-eslint/naming-convention */
import _ from 'lodash'

import { ProductOption } from './ProductOption'
import { ProductVariety } from './ProductVariety'

export type Product = {
  id: number
  client_category_name: string
  description: string
  image: string
  isDrink: boolean
  more: string
  options: Array<ProductOption>
  selected_options: Array<ProductOption>
  product_id: number
  product_name: string
  uniq_id: string
  uniq_ids: Array<string>
  varieties: Array<ProductVariety>
  variety: ProductVariety
  full_cost: number
  can6: boolean | number
  discont?: number
  temperature?: string
  availability_status?: 'in_stock' | 'ended' | 'not_available'
  is_combo?: boolean
  count: number
}

export const productVarietyIsAvailable = (product: Product): boolean => {
  if (!product.isDrink) {
    return true
  }
  if (product.varieties.length) {
    const available = _.findIndex(product.varieties, (item) =>
      item.availability_status === 'in_stock' ? true : false,
    )
    return available !== -1 ? true : false
  }
  return true
}

export const getProductPrice = (product: Product): number => {
  let tempPrice = 0
  if (product && product.varieties && product.varieties.length) {
    let min_val = _.sortBy(product.varieties, 'cost')
    min_val = _.filter(min_val, (item) => item.availability_status === 'in_stock')
    tempPrice += min_val && min_val[0] ? min_val[0].cost : 0
  }
  return tempPrice
}
