import { fetchActuallyOrders, fetchOrder, fetchOrdersHistory } from 'application/data/api/orders'
import { Order } from 'application/domain/entity/order/Order'
import { useQuery } from '@tanstack/react-query'

export const useOrdersHistory = (user_token: string) => {
  const query = useQuery(['orders', user_token || ''], fetchOrdersHistory, {
    enabled: user_token ? true : false,
    refetchInterval: user_token ? 10000 : false,
    retry: false,
  })

  if (query.data) {
    return {
      ...query,
      data: query.data.last_five_finish_orders ? query.data.last_five_finish_orders : [],
      freeCup:
        query.data && (query.data.free_cup || query.data.free_cup === 0) ? query.data.free_cup : 5,
    }
  }

  return { ...query, data: [], freeCup: 5 }
}

export const useActuallyOrders = (user_token: string) => {
  const query = useQuery(['actually', user_token || ''], fetchActuallyOrders, {
    enabled: user_token ? true : false,
    refetchInterval: user_token ? 10000 : false,
    retry: false,
  })

  return {
    ...query,
    data: {
      open_orders:
        query.data && query.data.open_orders
          ? query.data.open_orders.filter(
              (item: Order) => item.status !== 'Ожидает оплаты' && item.status !== 'Отменен',
            )
          : [],
      open_orders_list:
        query.data && query.data.open_orders_list ? query.data.open_orders_list : [],
    },
  }
}

export const useOrder = (id: number, isOpenOrder: boolean) => {
  const query = useQuery<Order>(
    [isOpenOrder ? 'open_order' : 'order', id],
    () => fetchOrder(id, isOpenOrder),
    {
      enabled: id ? true : false,
      refetchInterval: 10000,
    },
  )
  return query
}
