import React, { useMemo, useState } from 'react'
import cn from 'classnames'

import s from './HorizontalSelector.module.scss'

type Props = {
  selected: number
  data: Array<{
    id: number
    name: string
    value: string | number
    disabled?: boolean
  }>
  onChange: Function
  activeColor: 'orange' | 'aqua'
  containerStyle?: string
  vertical?: boolean
}

const HorizontalSelector = ({
  data,
  activeColor,
  selected,
  onChange,
  containerStyle,
  vertical,
}: Props) => {
  return (
    <div className={cn(s.container, vertical ? s.vertical : '', containerStyle || '')}>
      {data && data.length ? (
        data.map((item) => {
          return (
            <div
              className={cn(s.item, s[activeColor], item.id === selected ? s.selected : '')}
              style={{ width: `calc(100% / ${data.length})` }}
              key={`selector_item_${item.name}`}
              onClick={() => (!item.disabled ? onChange(item.id) : {})}
            >
              <div className={s.title}>{item.name}</div>
              <div className={s.subtitle}>{item.value}</div>
            </div>
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(HorizontalSelector)
