import { Button } from 'application/presentation/common/uiComponents'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import BottomSheet from '../BottomSheet'

import s from './ProductInfo.module.scss'

type Props = {
  data: string
}

const ProductInfoMW = ({ data }: Props) => {
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf('info') !== -1
  }, [location])

  return (
    <div>
      <BottomSheet
        opened={opened}
        autoHeight
        onSheetClosed={() => {
          if (location.hash.indexOf('info') !== -1) {
            navigation(-1)
          }
        }}
      >
        <div className={s.container}>
          <div className={s.txt}>{data ? data : ''}</div>
          <Button color="orange" text="Понятно" onClick={() => navigation(-1)} />
        </div>
      </BottomSheet>
    </div>
  )
}

export default React.memo(ProductInfoMW)
