import React, { useMemo, useState } from 'react'
import cn from 'classnames'

import s from './PageLayout.module.scss'

type PageLayoutContextType = {} | null

export const PageLayoutContext = React.createContext<PageLayoutContextType>(null)

export const PageLayoutProvider = ({ children }: any) => {
  const pageLayoutFunc = useMemo(() => ({}), [])

  return (
    <PageLayoutContext.Provider value={pageLayoutFunc}>
      <div className={s.page_layout}>
        <div className={cn(s.content)}>{children}</div>
      </div>
    </PageLayoutContext.Provider>
  )
}

export const { Consumer } = PageLayoutContext
