import React, { useCallback, useState } from 'react'
import apiClient from 'application/data/apiClient'
import baristaSvg from 'images/reviewIcons/barista.svg'
import coffeSvg from 'images/reviewIcons/coffe.svg'
import connectSvg from 'images/reviewIcons/connect.svg'
import kitchenSvg from 'images/reviewIcons/kitchen.svg'
import metlaSvg from 'images/reviewIcons/metla.svg'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { OrderType } from 'types/orderType'
import {
  Button,
  ImageComponent,
  PayButtons,
  PaySelector,
  TextInput,
} from 'application/presentation/common/uiComponents'
import { RootState } from 'application/domain/store/reduxStore'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'

import { TipsDataType } from '../useTipsPayment'

import s from './ReviewBlock.module.scss'

type Props = {
  rating: number
  data: OrderType
  createTips: (data: TipsDataType) => void
  saveIsLoading?: boolean
}

const tipsItems = [
  {
    id: 1,
    name: 'Другая\nсумма',
    sum: 0,
  },
  {
    id: 2,
    name: '15 ₽',
    sum: 15,
  },
  {
    id: 3,
    name: '30 ₽',
    sum: 30,
  },
  {
    id: 4,
    name: 'Без чаевых',
    sum: 0,
  },
]

const variableItems = [
  {
    id: 1,
    name: 'Бариста',
    image: baristaSvg,
  },
  {
    id: 2,
    name: 'Вкус кофе',
    image: coffeSvg,
  },
  {
    id: 3,
    name: 'Обслуживание',
    image: kitchenSvg,
  },
  {
    id: 4,
    name: 'Грязь',
    image: metlaSvg,
  },
  {
    id: 5,
    name: 'Общение',
    image: connectSvg,
  },
]

type SelectSumStageProps = {
  selectedPayMethod: number
  saveIsLoading: boolean
  create: (sum: number) => void
}

export const SelectSumStage = React.memo(({ saveIsLoading, create }: SelectSumStageProps) => {
  const [sum, setSum] = useState<string>('')
  return (
    <div className={s.select_sum_container}>
      <div className={s.select_sum_top}>
        <div className={s.sum_input_container}>
          <UniversalInputComponent
            type="number"
            title="Введите сумму чаевых для баристы"
            value={sum}
            onChange={setSum}
            placeholder="Введите сумму"
          />
          {/* <TextInput
            value={sum}
            onChange={(e: any) => setSum(e.target.value.replace(/^\d+$/, ''))}
            autoFocus
          /> */}
        </div>
      </div>

      <div className={s.select_sum_bottom}>
        <PaySelector />
        <PayButtons
          containerStyle={s.btn_container}
          isLoading={saveIsLoading}
          onClick={() => create(parseInt(sum, 10))}
        />
      </div>
    </div>
  )
})

type GoodReviewProps = {
  comment: string
  setComment: Function
  data: OrderType
  saveIsLoading: boolean
  selectedPayMethod: number
  create: (data: CreateTipProps) => void
}

const GoodReview = React.memo(
  ({ comment, setComment, data, saveIsLoading, selectedPayMethod, create }: GoodReviewProps) => {
    const [selectedTip, setSelectedTip] = useState<number>(2)
    const [selectSumStage, setSelectSumStage] = useState<boolean>(false)
    return selectSumStage ? (
      <SelectSumStage
        selectedPayMethod={selectedPayMethod}
        create={(sum: number) =>
          create({
            withPayment: true,
            selectedTip: 1,
            sum: sum,
          })
        }
        saveIsLoading={saveIsLoading}
      />
    ) : (
      <>
        <div className={s.title}>Вы можете оставить{'\n'}чаевые для Вашего бариста</div>
        <div className={s.barista}>
          <ImageComponent
            containerClassName={s.img_block}
            imageUrl={data.barista && data.barista.photo ? data.barista.photo : ''}
            type="barista"
            className={s.img}
          />
          {/* <div className={s.img_block}>
            <img
              src={
                data.barista && data.barista.photo
                  ? `${apiClient.defaults.baseURL}/${data.barista.photo}`
                  : baristaSvg
              }
              className={s.img}
            />
          </div> */}
          <div className={s.info}>
            <div className={s.barista_name}>{data.barista ? data.barista.name : 'Бариста'}</div>
            <div className={s.barista_subname}>Ваш бариста</div>
          </div>
        </div>
        <div className={s.input_container}>
          <TextInput
            value={comment}
            onChange={(e: any) => setComment(e.target.value)}
            placeholder="Вы можете оставить комментарий"
          />
        </div>
        <div className={s.tips_container}>
          {tipsItems.map((item) => (
            <div
              className={`${s.tips_item} ${item.id === selectedTip ? s.active : ''}`}
              key={`tips_item_${item.id}`}
              onClick={() => setSelectedTip(item.id)}
            >
              <div className={`${s.tips_txt} ${item.id === selectedTip ? s.txt_active : ''}`}>
                {item.name}
              </div>
            </div>
          ))}
        </div>
        <div>
          {selectedTip === 1 ? (
            <Button
              color="orange"
              text="Далее"
              onClick={() => setSelectSumStage(true)}
              containerClassName={s.btn_container}
            />
          ) : selectedTip === 4 ? (
            <Button
              color="orange"
              text="Отправить"
              onClick={() =>
                create({
                  withPayment: false,
                  selectedTip: selectedTip,
                })
              }
              isLoading={saveIsLoading}
              containerClassName={s.btn_container}
            />
          ) : (
            <>
              <PaySelector />
              <PayButtons
                containerStyle={s.btn_container}
                isLoading={saveIsLoading}
                onClick={() =>
                  create({
                    withPayment: true,
                    selectedTip: selectedTip,
                  })
                }
              />
            </>
          )}
        </div>
      </>
    )
  },
)

type BadReviewProps = {
  comment: string
  setComment: Function
  saveIsLoading: boolean
  create: (data: CreateTipProps) => void
}

const BadReview = React.memo(({ comment, setComment, saveIsLoading, create }: BadReviewProps) => {
  const [selectedVariables, setSelectedVariables] = useState<Array<string>>([])
  const variableClickHandler = useCallback(
    (name: string) => {
      const temp = _.cloneDeep(selectedVariables)
      const finded = _.findIndex(temp, (i) => i === name)
      if (finded !== -1) {
        setSelectedVariables(_.filter(temp, (i) => i !== name))
      } else {
        temp.push(name)
        setSelectedVariables(temp)
      }
    },
    [selectedVariables],
  )
  return (
    <>
      <div className={s.title}>Опишите, что вам не понравилось, чтобы улучшить наш сервис</div>
      <div className={s.tips_container}>
        {variableItems.map((item) => {
          const finded = _.findIndex(selectedVariables, (i) => i === item.name) !== -1
          return (
            <div
              className={s.variable_item}
              key={`variable_item${item.id}`}
              onClick={() => variableClickHandler(item.name)}
            >
              <div className={`${s.variable_image} ${finded ? s.variable_active : ''}`}>
                <img src={item.image} />
              </div>
              <div className={`${s.variable_txt} ${finded ? s.variable_active : ''}`}>
                {item.name}
              </div>
            </div>
          )
        })}
      </div>
      <div className={s.input_container}>
        <TextInput
          value={comment}
          onChange={(e: any) => setComment(e.target.value)}
          placeholder="Вы можете оставить комментарий"
        />
      </div>
      <Button
        color="orange"
        text="Отправить"
        onClick={() =>
          create({
            withPayment: false,
            selectedTip: 4,
            selectedVariables: selectedVariables,
          })
        }
        isLoading={saveIsLoading}
        containerClassName={s.btn_container}
      />
    </>
  )
})

type CreateTipProps = {
  withPayment: boolean
  selectedTip?: number
  selectedVariables?: Array<string>
  sum?: number
}

const ReviewBlock = ({ rating, data, createTips, saveIsLoading }: Props) => {
  const { selectedPayMethod, token } = useSelector(({ payReducer, userReducer }: RootState) => ({
    selectedPayMethod: payReducer.selectedPayMethod,
    token: userReducer.token,
  }))
  const [comment, setComment] = useState<string>('')

  const create = useCallback(
    ({ withPayment, selectedTip, selectedVariables, sum }: CreateTipProps) => {
      const tip = _.find(tipsItems, { id: selectedTip })
      const sendData: TipsDataType = {
        variables: selectedVariables || [],
        sum: sum ? sum : tip ? tip.sum : 0,
        comment,
        withPayment,
        selected_pay_method: selectedPayMethod,
      }
      return createTips(sendData)
    },
    [comment, createTips, selectedPayMethod],
  )

  return (
    <div className={s.container}>
      {rating <= 3 ? (
        <BadReview
          comment={comment}
          setComment={setComment}
          saveIsLoading={saveIsLoading || false}
          create={create}
        />
      ) : (
        <GoodReview
          comment={comment}
          setComment={setComment}
          saveIsLoading={saveIsLoading || false}
          create={create}
          data={data}
          selectedPayMethod={selectedPayMethod}
        />
      )}
    </div>
  )
}

export default React.memo(ReviewBlock)
