import apiClient from 'application/data/apiClient'
import React from 'react'
import { useDispatch } from 'react-redux'
import queryClient from 'application/data/apiClient/queryClient'
import { setUser, setUserToken } from 'application/domain/store/userReducer'

type AuthContextType = {
  login: (data: DataProps) => void
  logout: Function
  registration: (data: DataProps) => void
  sendCode: (data: DataProps) => void
  checkCode: (data: DataProps) => void
  sendPassResetCode: (data: DataProps) => void
  changePassword: (data: DataProps) => void
}

type DataProps = {
  data: any
  onStart: Function
  onSuccess: Function
  onError: Function
}

export const AuthContext = React.createContext<AuthContextType>({
  login: () => {},
  logout: () => {},
  registration: () => {},
  sendCode: () => {},
  checkCode: () => {},
  sendPassResetCode: () => {},
  changePassword: () => {},
})

export const Authorization = ({ children }: any) => {
  const dispatch = useDispatch()

  const auth = React.useMemo(
    () => ({
      login: async ({ data, onStart, onSuccess, onError }: DataProps) => {
        onStart()
        const authDat = {
          phone: data.phone,
          password: data.password,
        }

        apiClient({
          url: '/api/login',
          method: 'POST',
          data: authDat,
        })
          .then((response: any) => {
            if (response.data.status === 'FAIL') {
              onError(response.data.message)
            } else {
              setUser(response.data.data.user)(dispatch)
              if (response.data.data.token) {
                setUserToken(response.data.data.token)(dispatch)

                //@ts-ignore
                apiClient.defaults.headers.Authorization = `Bearer ${response.data.data.token}`
                localStorage.setItem('user_token', response.data.data.token)
              }
              localStorage.setItem('phone', data.phone)
              localStorage.setItem('password', data.password)
              onSuccess(response.data)
            }
          })
          .catch(() => {
            onError('Неверный логин или пароль')
          })
      },
      registration: async ({ data, onStart, onSuccess, onError }: DataProps) => {
        onStart()
        const authDat = {
          phone: data.phone,
          password: data.password,
          password_confirmation: data.password,
        }

        if (data.password.length < 4) {
          return onError('Введите 4-х значный пинкод')
        }

        apiClient({
          url: '/api/register',
          method: 'POST',
          data: authDat,
        })
          .then((response: any) => {
            if (response.data.status === 'FAIL') {
              onError(response.data.message)
            } else {
              // dispatch(setUser(response.data.data))
              // if (response.data.data.token) {
              //   apiClient.defaults.headers.Authorization = `Bearer ${response.data.data.token}`
              //   AsyncStorage.setItem('user_token', response.data.data.token)
              // }
              // AsyncStorage.setItem('phone', data.phone)
              // AsyncStorage.setItem('password', data.password)
              auth.login({ data: authDat, onStart, onSuccess, onError })
              // onSuccess(response.data)
            }
          })
          .catch((error: any) => {
            onError('Ошибка')
          })
      },
      checkCode: async ({ data, onStart, onSuccess, onError }: DataProps) => {
        onStart()
        const authDat = {
          phone: data.phone,
          pin: data.pin,
        }

        apiClient({
          url: '/api/attempt',
          method: 'POST',
          data: authDat,
        })
          .then((response: any) => {
            if (response.data.status === 'FAIL') {
              onError(response.data.message)
            } else {
              onSuccess(response.data)
            }
          })
          .catch(() => {
            onError('Ошибка')
          })
      },
      sendCode: async ({ data, onStart, onSuccess, onError }: DataProps) => {
        onStart()

        const authDat = {
          phone: data.phone,
        }

        apiClient({
          url: '/api/newClient',
          method: 'POST',
          data: authDat,
        })
          .then((response: any) => {
            if (response.data.status === 'FAIL') {
              onError(response.data.message)
            } else {
              onSuccess(response.data)
            }
          })
          .catch(({ response }: any) => {
            onError(response.data && response.data.message ? response.data.message : 'Ошибка')
          })
      },
      sendPassResetCode: async ({ data, onStart, onSuccess, onError }: DataProps) => {
        onStart()

        const authDat = {
          phone: data.phone,
        }

        apiClient({
          url: '/api/resetPassword',
          method: 'POST',
          data: authDat,
        })
          .then((response: any) => {
            if (response.data.status === 'FAIL') {
              onError(response.data.message)
            } else {
              onSuccess(response.data)
            }
          })
          .catch(({ response }: any) => {
            onError(response.data && response.data.message ? response.data.message : 'Ошибка')
          })
      },
      changePassword: async ({ data, onStart, onSuccess, onError }: DataProps) => {
        onStart()

        const authDat = {
          phone: data.phone,
          password: data.password,
          password_confirmation: data.password,
          code: data.pin,
        }

        apiClient({
          url: '/api/changePassword',
          method: 'POST',
          data: authDat,
        })
          .then((response: any) => {
            if (response.data.status === 'FAIL') {
              onError(response.data.message)
            } else {
              onSuccess(response.data)
            }
          })
          .catch((error: any) => {
            if (error.response.data.errors) {
              onError(error.response.data.errors)
            } else {
              onError('Ошибка')
            }
          })
      },
      logout: (callback?: Function) => {
        queryClient.cancelQueries(['profile'])
        setUser(null)(dispatch)
        setUserToken(null)(dispatch)
        localStorage.removeItem('login')
        localStorage.removeItem('password')
        localStorage.removeItem('user_token')
        localStorage.removeItem('phone')

        //@ts-ignore
        apiClient.defaults.headers.Authorization = ''

        if (callback) {
          callback()
        }
        // return dispatch({ type: 'SIGN_OUT' })
      },
    }),
    [dispatch],
  )

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
