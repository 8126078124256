import queryClient from 'application/data/apiClient/queryClient'
import {
  deleteUserPaymentCardsRequest,
  fetchUserPaymentCards,
} from 'application/data/api/userPaymentCards'
import { UserPaymentCard } from 'application/domain/entity/userCards/UserPaymentCard'
import _ from 'lodash'
import { useMutation, useQuery } from '@tanstack/react-query'

export const DeleteCardMutation = (token: string | null) => {
  const mutation = useMutation(
    ({ card_id }: { card_id: number }) => deleteUserPaymentCardsRequest(card_id),
    {
      onMutate: async ({ card_id }: { card_id: number }) => {
        await queryClient.cancelQueries(['cards'])
        const previousData = queryClient.getQueryData<any>(['cards', token || ''])
        if (previousData) {
          queryClient.setQueryData(['cards', token || ''], {
            ...previousData,
            cards: _.filter(previousData.cards, (card) => {
              return card.id !== card_id
            }),
          })
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        queryClient.setQueryData(['cards', token || ''], context.previousData)
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(['cards'])
      },
    },
  )
  return mutation
}

export const getUserPaymentCards = async () => {
  const data = await fetchUserPaymentCards()
  return data ? data.cards : []
}

export const useUserPaymentCards = (token: string | null) => {
  const query = useQuery<{ cards: Array<UserPaymentCard> }>(
    [['cards', token || '']],
    fetchUserPaymentCards,
    {
      refetchInterval: 20000,
      enabled: token ? true : false,
    },
  )
  return { ...query, data: query.data && query.data.cards ? query.data.cards : [] }
}
