import React from 'react'
import cn from 'classnames'

import s from './ErrorMsg.module.scss'

type Props = {
  text: string
  containerStyle?: any
  style?: any
}

const ErrorMsg = ({ text, containerStyle, style }: Props) => {
  return (
    <div className={containerStyle || ''}>
      <div className={cn(s.msg, style || '')}>{text}</div>
    </div>
  )
}

export default React.memo(ErrorMsg)
