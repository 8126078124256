import { useCallback, useState } from 'react'
import apiClient from 'application/data/apiClient'
import mainPayProcess from 'application/domain/useCase/payments/MainPayProcess'

export type OutletTipsDataType = {
  comment: string
  sum: number
  selected_pay_method: number
  outlet_id: number
}

type Props = {
  setSberOrderId: Function
  setPaymentCheckStage: Function
  setIsSuccess: Function
}

export const useOutletTipsPayment = ({
  setSberOrderId,
  setPaymentCheckStage,
  setIsSuccess,
}: Props) => {
  const [tipsSaveIsLoading, setSaveTipsIsLoading] = useState<boolean>(false)

  const onPaySuccess = useCallback(
    (stat: string, response?: any) => {
      if (stat === '3ds') {
        if (response && response.externalParams && response.externalParams.sbolDeepLink) {
          window.location.href = response.externalParams.sbolDeepLink
        } else {
          window.location.href = response.formUrl
        }
        setPaymentCheckStage(true)
        setSberOrderId(response.orderId)
      } else if (stat === 'success') {
        setSaveTipsIsLoading(false)
        setIsSuccess(true)
        // navigate(`${window.location.href}#result`, { replace: true })
      } else {
        if (response) {
          const urlParams = new URLSearchParams(response.redirect.split('?')[1])
          setSberOrderId(urlParams.get('orderId'))
          setSaveTipsIsLoading(false)
          setPaymentCheckStage(true)
        }
      }
    },
    [setPaymentCheckStage, setSberOrderId, setIsSuccess],
  )

  const onPayError = useCallback((type?: string, message?: string) => {
    console.log('payError')
    setSaveTipsIsLoading(false)
  }, [])

  const createTips = useCallback(
    (tipsData: OutletTipsDataType) => {
      setSaveTipsIsLoading(true)
      const comment = tipsData.comment
      apiClient
        .post('/api/tips', {
          sum: tipsData.sum,
          comment: comment,
          outlet_id: tipsData.outlet_id,
        })
        .then((response) => {
          const payData = {
            data: {
              tips_id: response.data.id,
              selected_pay_method: tipsData.selected_pay_method,
              is_outlet_tips: true,
            },
            onStart: () => {},
            onSuccess: onPaySuccess,
            onError: onPayError,
          }

          //@ts-ignore
          mainPayProcess(payData)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            console.log(error.response.data)
          }
          setSaveTipsIsLoading(false)
        })
    },
    [onPaySuccess, onPayError],
  )

  const onBackPress = useCallback(() => {
    setSaveTipsIsLoading(false)
    setPaymentCheckStage(false)
  }, [setPaymentCheckStage])

  return {
    createTips,
    tipsSaveIsLoading,
    onBackPress,
    onPaySuccess,
  }
}
