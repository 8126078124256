import { setMergedBasket, toggleBalance } from 'application/domain/store/basketReducer'
import { RootState } from 'application/domain/store/reduxStore'
import { getSumWithBonus } from 'application/domain/useCase/basket/balanceAction'
import { groupBasketData, mergeBasketData } from 'application/domain/useCase/basket/basketActions'
import { useBalance } from 'application/domain/useCase/user/getUser'
import Header from 'application/presentation/common/Header/Header'
import { BasketBottomBar, SelectOutletBlock } from 'application/presentation/common/uiComponents'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alohaIds, useOutlets } from 'application/domain/useCase/outlets/getOutlets'
import { getTechnicalBreak } from 'application/domain/useCase/outlets/outletActions'

import s from './BasketPage.module.scss'
import AddProductsBlock from './components/AddProductsBlock'
import BalanceBlock from './components/BalanceBlock'
import BasketList from './components/BasketList'
import { useBasket } from './useBasket'

const BasketPage = () => {
  const dispatch = useDispatch()
  const { basketData, user, token, outlet, isBalance } = useSelector(
    ({ userReducer, basketReducer }: RootState) => ({
      token: userReducer.token,
      outlet: userReducer.outlet,
      basketData: basketReducer.basket,
      user: userReducer.user,
      isBalance: basketReducer.isBalance,
    }),
  )
  const { data: balanceData } = useBalance(user)
  const { data: outlets } = useOutlets()

  const setIsBalance = useCallback(() => {
    toggleBalance(isBalance)(dispatch)
  }, [dispatch, isBalance])

  const technicalBreak = useMemo(() => {
    if (outlets.length) {
      return getTechnicalBreak(outlet ? _.find(outlets, { id: outlet.id }) : outlets[0])
    }
    return null
  }, [outlets, outlet])

  const {
    initBasket,
    isLoading: basketIsLoading,
    fullPrice,
    somethingNotInStock,
    calculatedBasket,
    error,
  } = useBasket({ outlet, basket: basketData, token })

  useEffect(() => {
    if (basketData) {
      initBasket()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketData])

  const mergedData = useMemo(() => {
    const merged = mergeBasketData(basketData, calculatedBasket)
    setMergedBasket(merged)(dispatch)
    return merged
  }, [basketData, calculatedBasket, dispatch])

  const uniqData = useMemo(() => groupBasketData(mergedData), [mergedData])

  const balance: {
    cashback_sum: number
    cards_sum: number
  } = useMemo(() => {
    return balanceData ? balanceData : null
  }, [balanceData])

  const sumWithBonus = useMemo(() => {
    if (outlet && mergedData) {
      const sum = getSumWithBonus(fullPrice, outlet, balance, isBalance, user, mergedData)
      return sum
    }
    return {
      cost: 0,
      bonus: 0,
    }
  }, [balance, fullPrice, isBalance, mergedData, outlet, user])

  const btnDisabled: boolean = useMemo(() => {
    return technicalBreak || basketIsLoading || somethingNotInStock || error || !basketData.length
      ? true
      : false
  }, [basketData, basketIsLoading, error, somethingNotInStock, technicalBreak])

  return (
    <div className={s.container}>
      <Header title="Корзина" />
      <div className={s.location_container}>
        <div className={s.location_title}>Получи заказ по адресу:</div>
        <SelectOutletBlock technicalBreak={technicalBreak} />
      </div>
      <BasketList data={uniqData} basketIsLoading={basketIsLoading} />
      {basketData && basketData.length ? <AddProductsBlock /> : <></>}
      {user && (
        <BalanceBlock
          isBalance={isBalance}
          setIsBalance={setIsBalance}
          balance={
            alohaIds.indexOf(outlet?.id || 0) !== -1 ? balance?.cashback_sum : balance?.cards_sum
          }
        />
      )}
      <BasketBottomBar
        sumWithBonus={sumWithBonus}
        price={fullPrice}
        basketIsLoading={basketIsLoading}
        somethingNotInStock={somethingNotInStock}
        disabled={btnDisabled}
      />
    </div>
  )
}
export default React.memo(BasketPage)
