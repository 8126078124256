import Loader from 'application/presentation/common/Loader'
import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './Button.module.scss'

export type BtnColorType = 'red' | 'white' | 'transparent' | 'gray' | 'green' | 'orange'

type Props = {
  text: string | React.ReactElement
  color?: BtnColorType
  isLink?: boolean
  to?: string
  containerClassName?: string
  className?: string
  iconUrl?: string
  withoutShadow?: boolean
  onClick?: Function
  isLoading?: boolean
  stopEvents?: boolean
  disabled?: boolean
}

const Button = ({
  text,
  isLink,
  to,
  color,
  containerClassName,
  className,
  iconUrl,
  withoutShadow,
  onClick,
  isLoading,
  stopEvents,
  disabled,
}: Props) => {
  if (isLink) {
    return (
      <div className={`${s.btn_container} ${containerClassName || ''}`}>
        <NavLink
          to={to || ''}
          className={`${s.btn} ${color ? s[color] : ''} ${iconUrl ? s.with_icon : ''} ${
            withoutShadow ? s.withoutShadow : ''
          } ${className || ''} ${isLoading || disabled ? s.disabled : ''}`}
          onClick={(e: any) => {
            if (stopEvents) {
              e.preventDefault()
              e.stopPropagation()
            }
            if (onClick) {
              onClick()
            }
          }}
        >
          {isLoading ? (
            <Loader color={'#bfbfbf'} size={16} />
          ) : (
            <>
              {iconUrl ? <img src={iconUrl} alt="" /> : <></>}
              <span>{text}</span>
            </>
          )}
        </NavLink>
      </div>
    )
  }
  return (
    <div className={`${s.btn_container} ${containerClassName || ''}`}>
      <button
        type="button"
        disabled={isLoading}
        className={`${s.btn} ${color ? s[color] : ''} ${iconUrl ? s.with_icon : ''} ${
          withoutShadow ? s.withoutShadow : ''
        } ${className || ''} ${isLoading || disabled ? s.disabled : ''}`}
        onClick={(e: any) => {
          if (stopEvents) {
            e.preventDefault()
            e.stopPropagation()
          }
          if (onClick) {
            onClick()
          }
        }}
      >
        {isLoading ? (
          <Loader color="#bfbfbf" size={12} />
        ) : (
          <>
            {iconUrl ? <img src={iconUrl} alt="" /> : <></>}
            <span>{text}</span>
          </>
        )}
      </button>
    </div>
  )
}

export default React.memo(Button)
