/* eslint-disable @typescript-eslint/naming-convention */
import { Order } from 'application/domain/entity/order/Order'
import { getProductPrice } from 'application/domain/entity/product/Product'
import { RootState } from 'application/domain/store/reduxStore'
import { useCategories } from 'application/domain/useCase/categories/getCategories'
import { useActuallyOrders } from 'application/domain/useCase/orders/getOrders'
import { useProducts } from 'application/domain/useCase/products/getProducts'
import { MainPageHeader } from 'application/presentation/common/Header'
import Loader from 'application/presentation/common/Loader'
import { HelloMW } from 'application/presentation/common/ModalWindows'
import { Button } from 'application/presentation/common/uiComponents'
import splitByTwo from 'application/utils/splitByTwo'
import _ from 'lodash'
import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import ActuallyOrder from './components/ActuallyOrder'
import CategoryChips from './components/CategoryChips'
import ControllBlock from './components/ControllBlock'
import ProductsList from './components/ProductsList'
import s from './MainPage.module.scss'

const MainPage = () => {
  const navigate = useNavigate()
  const { basketCount, outlet, token } = useSelector(
    ({ basketReducer, userReducer }: RootState) => ({
      basketCount: basketReducer.basketCount,
      outlet: userReducer.outlet,
      token: userReducer.token,
    }),
  )
  const { data: categoriesData, isLoading: categoriesIsLoading } = useCategories(
    outlet ? outlet.id : 0,
  )
  const { data: productsData, isLoading: productsIsLoading } = useProducts(outlet ? outlet.id : 0)
  const { data: actuallyOrders } = useActuallyOrders(token || '')

  const data: any = useMemo(() => {
    let dat: any = []
    let cat: any = []
    if (categoriesData && productsData) {
      let products = _.filter(
        productsData,
        (item) => getProductPrice(item) > 0 && item.availability_status === 'in_stock',
      )
      categoriesData.map((item: any, i: number) => {
        const categoryProducts = _.filter(products, { client_category_name: item.name })
        if (categoryProducts && categoryProducts.length) {
          cat.push(item)
          dat.push({
            title: item.name,
            id: item.id,
            key: `title_${item.name}`,
            type: 'title',
            products: splitByTwo(categoryProducts),
          })
        }
      })
    }
    return {
      products: dat,
      categories: cat,
    }
  }, [categoriesData, productsData])

  const lastOrder = useMemo(
    () =>
      actuallyOrders && actuallyOrders.open_orders_list.length
        ? actuallyOrders.open_orders_list[0]
        : null,
    [actuallyOrders],
  )

  const openedOrders = useMemo(() => {
    if (actuallyOrders && actuallyOrders.open_orders.length) {
      const op_o = actuallyOrders.open_orders.filter((o: Order) => {
        if (o && o !== null) {
          return (
            o.status && o.status != null && o.status !== 'Ожидает оплаты' && o.status !== 'Отменен'
          )
        }
        return false
      })
      return op_o
    }
    return []
  }, [actuallyOrders])

  useEffect(() => {
    if (!outlet) {
      navigate('/outlets', { replace: true })
    }
  }, [outlet, navigate])

  return categoriesIsLoading || productsIsLoading ? (
    <Loader />
  ) : (
    <>
      <div className={s.container} id="main_scroll">
        <MainPageHeader />
        <ControllBlock />
        <div className={s.sticky_container}>
          <ActuallyOrder openedOrders={openedOrders} lastOrder={lastOrder} />
          <CategoryChips data={data.categories} />
        </div>
        <ProductsList data={data.products} />
      </div>
      {basketCount > 0 && (
        <div className={s.button_container}>
          <Button color="orange" text="Оформить" isLink to="/basket" />
        </div>
      )}
      <HelloMW />
    </>
  )
}

export default React.memo(MainPage)
