import { Product } from 'application/domain/entity/product/Product'
import { AxiosRequestConfig } from 'axios'

import apiClient from '../apiClient'

export const fetchCalculatedBasket = async (
  products: Array<Product>,
  outlet_id: number,
  config?: AxiosRequestConfig,
) => {
  const { data } = await apiClient.post(
    '/api/items_calculation',
    {
      products,
      outlet_id,
      calculate_type: 'app',
    },
    config,
  )
  return data
}
