import apiClient from 'application/data/apiClient'
import ToastNotify from 'application/presentation/context/ToastNotify'
import { ProductHeader } from 'application/presentation/common/Header'
import Loader from 'application/presentation/common/Loader'
import { Button, HorizontalSelector } from 'application/presentation/common/uiComponents'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ConfirmationMW, ProductInfoMW } from 'application/presentation/common/ModalWindows'
import {
  addAdditionalToProduct,
  editSelectedOptionsBySelectedVolume,
  removeAdditionalFromProduct,
} from 'application/domain/useCase/products/productsAction'
import { Product } from 'application/domain/entity/product/Product'
import { ProductVariety } from 'application/domain/entity/product/ProductVariety'
import { addProductToBasket } from 'application/domain/useCase/basket/addToBasket'
import { useProduct } from 'application/domain/useCase/products/getProducts'
import { RootState } from 'application/domain/store/reduxStore'
import ProductImage from 'application/presentation/common/uiComponents/ProductImage'

import s from './ProductPage.module.scss'
import AdditionalsSelector from './components/AdditionalsSelector'

export const tempSelector = [
  {
    id: 1,
    name: 'Теплый',
    value: '',
  },
  {
    id: 2,
    name: 'Горячий',
    value: '',
  },
]

const ProductPage = () => {
  const { outlet } = useSelector(({ userReducer }: RootState) => ({ outlet: userReducer.outlet }))
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const productId = useMemo(() => (params && params.id ? parseInt(params.id, 10) : 0), [params])
  const { data, isLoading } = useProduct(productId, outlet ? outlet.id : 0)

  const [selectedVolume, setSelectedVolume] = useState<number>(1)
  const [selectedTemp, setSelectedTemp] = useState<number>(1)
  const [additionalItem, setAdditionalItem] = useState<any>()
  const [confirmationTitle, setConfirmationTitle] = useState<string>('')

  const [product, setProduct] = useState<Product>()
  useEffect(() => {
    if (data) {
      setProduct(data)
    }
  }, [data])
  const volumeSelector = useMemo(() => {
    if (data && data.varieties) {
      const v = _.filter(data.varieties, (item) => item.availability_status !== 'not_available')
      return _.sortBy(
        v.map((item: ProductVariety) => ({
          ...item,
          id: item.variety_id,
          value: item.availability_status === 'in_stock' ? `${item.cost} ₽` : 'Закончился',
          disabled: item.availability_status === 'in_stock' ? false : true,
        })),
        'cost',
      )
    }
    return undefined
  }, [data])

  useEffect(() => {
    if (volumeSelector) {
      const select = _.find(
        volumeSelector,
        (item: ProductVariety) => item.availability_status === 'in_stock',
      )
      setSelectedVolume(select ? select.id : 0)
    }
  }, [volumeSelector])

  const price = useMemo(() => {
    let p = 0
    const sel: any = _.filter(product?.selected_options, { selected: true })
    if (sel) {
      sel.map((item: any) => {
        if (item.items) {
          item.items.map((item2: any) => {
            if (item2.selected) {
              p += item2.cost
            }
          })
        }
      })
    }
    if (volumeSelector) {
      volumeSelector.map((item: any) => {
        if (item.id === selectedVolume) {
          p += item.cost
        }
      })
    }
    return p
  }, [volumeSelector, selectedVolume, product])

  const notIncludedAdditionals = useMemo(() => {
    return product
      ? _.filter(
          _.map(product.options, (item) => {
            return {
              ...item,
              items: _.filter(item.items, (o) => o.availability_status !== 'not_available'),
            }
          }),
          (item) => item.items.length > 0,
        )
      : []
  }, [product])

  const selectedAdditionals = useMemo(() => {
    return product && product.selected_options ? product.selected_options : []
  }, [product])

  const selectAdditional = useCallback(
    (item: any, addId: number) => {
      const newProd = addAdditionalToProduct({
        //@ts-ignore
        product,
        optionId: item.id,
        optionItemId: addId,
      })
      setProduct(newProd)
    },
    [product],
  )

  const addProduct = useCallback(() => {
    if (!product) {
      return false
    }
    addProductToBasket(product, {
      selected_temperature: selectedTemp,
      selected_volume: selectedVolume,
      callback: () => {
        ToastNotify(`${product.product_name} добавлен`)
        navigation(-1)
      },
    })(dispatch)
  }, [product, selectedTemp, selectedVolume, navigation, dispatch])

  const removeAdditional = useCallback(() => {
    const newProd = removeAdditionalFromProduct({
      //@ts-ignore
      product,
      optionId: additionalItem.id,
      optionItemId: additionalItem.items[0].id,
    })
    setProduct(newProd)
  }, [additionalItem, product])

  const openRemoveAdditional = useCallback(
    (item: any) => {
      setAdditionalItem(item)
      setConfirmationTitle(`Вы уверены, что хотите удалить ${item.name} ${item.items[0].name}?`)
      navigation('#confirmation')
    },
    [navigation],
  )

  useEffect(() => {
    if (product && selectedVolume && product.isDrink) {
      setProduct(editSelectedOptionsBySelectedVolume(product, selectedVolume))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVolume])

  return (
    <div className={s.container}>
      <ProductHeader />

      {isLoading ? (
        <Loader />
      ) : product ? (
        <div className={s.content}>
          <ProductImage
            containerClassName={s.image}
            isDrink={product.isDrink}
            imageUrl={product.image}
          />
          <div className={s.name}>{product.product_name}</div>
          {product.description ? <div className={s.desc}>{product.description}</div> : <></>}
          <div className={s.horizontal_selectors}>
            {volumeSelector && volumeSelector.length > 1 ? (
              <HorizontalSelector
                data={volumeSelector}
                activeColor="orange"
                selected={selectedVolume}
                onChange={setSelectedVolume}
                containerStyle={s.horiz_selector_container}
                vertical={product.isDrink && volumeSelector.length <= 3 ? false : true}
              />
            ) : null}

            {product.isDrink && product.can6 ? (
              <HorizontalSelector
                data={tempSelector}
                activeColor="aqua"
                selected={selectedTemp}
                onChange={setSelectedTemp}
                containerStyle={s.horiz_selector_container}
              />
            ) : (
              <></>
            )}
            <AdditionalsSelector
              selected={selectedAdditionals}
              notIncluded={notIncludedAdditionals}
              selectAdditional={selectAdditional}
              removeAdditional={openRemoveAdditional}
              selectedVolume={selectedVolume}
            />
          </div>
          <ProductInfoMW data={product.description} />
          <ConfirmationMW title={confirmationTitle} onConfirm={removeAdditional} />
        </div>
      ) : (
        <div>Продукт не найден</div>
      )}
      {product ? (
        <>
          <div className={s.button_container}>
            <Button
              isLink
              to="#"
              text={
                product.availability_status && product.availability_status !== 'not_available'
                  ? product.availability_status === 'in_stock'
                    ? `Добавить за ${price} ₽`
                    : 'Нет в наличии'
                  : 'Товар недоступен'
              }
              color={
                !product.availability_status || product.availability_status !== 'in_stock'
                  ? 'white'
                  : 'orange'
              }
              disabled={!product.availability_status || product.availability_status !== 'in_stock'}
              onClick={addProduct}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <Portal>
        
      </Portal> */}
    </div>
  )
}

export default React.memo(ProductPage)
