/* eslint-disable @typescript-eslint/naming-convention */
import { fetchCalculatedBasket } from 'application/data/api/basket'
import { Product } from 'application/domain/entity/product/Product'
import { ProductOption, ProductOptionItem } from 'application/domain/entity/product/ProductOption'
import { AxiosRequestConfig } from 'axios'
import _ from 'lodash'

export const calculateBasket = async (
  products: Array<any>,
  outlet_id: number,
  config?: AxiosRequestConfig,
) => {
  const data = await fetchCalculatedBasket(products, outlet_id, config)
  return data
}

export const mergeBasketData = (currentBasket: any, calculatedBasket: any) => {
  if (calculatedBasket.length && currentBasket.length) {
    const temp = _.cloneDeep(currentBasket)

    return temp.map((item: any, i: number) => {
      if (calculatedBasket[i]) {
        item.full_cost = calculatedBasket[i].full_cost
        if (calculatedBasket[i].temperature) {
          item.temperature = calculatedBasket[i].temperature
        }
        if (calculatedBasket[i].availability_status) {
          item.availability_status = calculatedBasket[i].availability_status
        }
        if (calculatedBasket[i].options) {
          item.options.map((o: ProductOption) => {
            o.items.map((v) => {
              calculatedBasket[i].options.map((new_o: ProductOptionItem) => {
                if (new_o.id == v.variety_id) {
                  v.availability_status = new_o.availability_status
                }
              })
              return v
            })
            return item
          })
        }
        // if (calculatedBasket[i].variety) {
        //   item.variety = calculatedBasket[i].variety
        // }
        item.discont = calculatedBasket[i].discont || 0
        if (item.variety && item.variety.cost) {
          item.variety.cost = calculatedBasket[i].variety.cost
          item.variety.availability_status = calculatedBasket[i].variety.availability_status
          item.variety.name = calculatedBasket[i].variety.name
        } else {
          item.variety = calculatedBasket[i].variety
        }
      }
      return item
    })
  }
  return currentBasket
}

export const checkIsSomethingNotInStock = (data: Array<Product>) => {
  const finded = _.find(data, (p) => {
    return p.availability_status === 'not_available' || p.availability_status === 'ended'
  })
  if (finded) {
    return true
  } else {
    let optionNotAvailable = false
    data.map((item: Product) => {
      if (item.varieties) {
        const f = _.find(item.varieties, (v) => {
          return (
            v.selected === true &&
            (v.availability_status === 'not_available' || v.availability_status === 'ended')
          )
        })
        if (f) {
          optionNotAvailable = true
        }
      }
      if (item.options) {
        item.options.map((o: ProductOption) => {
          const f = _.find(o.items, (v) => {
            return (
              v.selected === true &&
              (v.availability_status === 'not_available' || v.availability_status === 'ended')
            )
          })
          if (f) {
            optionNotAvailable = true
          }
        })
      }
    })
    return optionNotAvailable ? true : false
  }
}

const isEqual = (a: Product, b: Product) => {
  return (
    _.isEqual(a.product_name, b.product_name) &&
    _.isEqual(a.varieties, b.varieties) &&
    _.isEqual(a.selected_options, b.selected_options) &&
    a.temperature === b.temperature &&
    (a.variety && b.variety ? a.variety.id === b.variety.id : false)
  )
}

export const groupBasketData = (basket: any) => {
  const currentBasket = _.cloneDeep(basket)
  const withDiscont = _.filter(currentBasket, (i) => i.discont && i.discont > 0)
  const withoutDiscont = _.filter(currentBasket, (i) => !i.discont || i.discont === 0)
  const uniq = _.uniqWith(withoutDiscont, (a: Product, b: Product) => {
    return isEqual(a, b)
  })
  const uniq_items = uniq.map((item) => {
    let c = 0
    let sum = 0
    let uniq_ids: Array<string> = []
    withoutDiscont.map((basketItem: Product) => {
      if (isEqual(item, basketItem)) {
        c = c + 1
        sum = sum + basketItem.full_cost
        uniq_ids.push(basketItem.uniq_id)
      }
    })
    return { ...item, count: c, uniq_ids, full_cost: sum }
  })
  return withDiscont && withDiscont.length ? _.concat(withDiscont, uniq_items) : uniq_items
}
