/* eslint-disable @typescript-eslint/naming-convention */
import { Outlet } from 'application/domain/entity/outlet/Outlet'
import moment from 'moment'

export const checkOutletWorkTime = (bar: Outlet) => {
  let time_work = bar.time_work
  const now = moment()
  time_work = time_work.replace(/\s+/g, '').trim()
  let splitted = time_work.split('-')
  if (!splitted[1]) {
    splitted = time_work.split('–')
  }
  const start = splitted[0]
  const end = splitted[1]
  let endTime = moment().format('YYYY-MM-DD')
  const startTime = `${endTime} ${start}`
  endTime = `${endTime} ${end}`
  const endDate = moment(endTime)
  const startDate = moment(startTime)
  return now.isBefore(startDate) || endDate.diff(now, 'minutes') <= 10 ? true : false
}

export const getTechnicalBreak = (outlet: Outlet): string | null => {
  if (outlet.technical_break && outlet.technical_break.indexOf('00:00') !== -1) {
    return null
  }
  return outlet.technical_break || null
}
