import { Outlet } from 'application/domain/entity/outlet/Outlet'
import { setOutlet, setTableId } from 'application/domain/store/userReducer'
import { useOutlets } from 'application/domain/useCase/outlets/getOutlets'
import Loader from 'application/presentation/common/Loader'
import OutletItem from 'application/presentation/common/OutletItem'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ReactComponent as PacketSvg } from 'images/icons/packet.svg'
import tablePng from 'images/table.png'
import { RootState } from 'application/domain/store/reduxStore'

import s from './OutletsListPage.module.scss'

const OutletsListPage = () => {
  const { outlet } = useSelector(({ userReducer }: RootState) => ({
    outlet: userReducer.outlet,
  }))
  const [stage, setStage] = useState<number>(1)
  const { data, isLoading } = useOutlets(outlet ? outlet.id : 0)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectBar = useCallback(
    (item: Outlet) => {
      sessionStorage.setItem('outlet_id', item.id.toString())
      setOutlet(item)(dispatch)
      setStage(2)
    },
    [dispatch],
  )

  const selectTable = useCallback(
    (id: string) => {
      setTableId(id)(dispatch)
      navigate('/', { replace: true })
    },
    [navigate, dispatch],
  )

  return (
    <div className={s.container}>
      {stage === 1 && (
        <>
          <div className={s.title}>
            Выберите кофе-бар
            <br />
            для мобильного заказа
          </div>
          {isLoading ? (
            <Loader size={32} />
          ) : (
            <div className={s.outlets}>
              {data.map((item: Outlet) => (
                <OutletItem data={item} onClick={selectBar} key={`outlet_item_${item.id}`} />
              ))}
            </div>
          )}
        </>
      )}
      {stage === 2 && (
        <>
          <div className={s.title}>Как будем заказывать?</div>
          <div className={s.outlets}>
            <div className={s.item} onClick={() => selectTable('0')}>
              <div className={s.center}>
                <div className={s.title}>С собой</div>
              </div>
              <div className={s.img}>
                <PacketSvg />
              </div>
            </div>
            <div className={s.item} onClick={() => selectTable('-2')}>
              <div className={s.center}>
                <div className={s.title}>В зале</div>
                <div className={s.subtitle}>На свободный стол</div>
              </div>
              <div className={s.img}>
                <img src={tablePng} alt="" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(OutletsListPage)
